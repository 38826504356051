const AlertIcon = ({ width = '14', height = '12' }) => (
  <svg width={width} height={height} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.42626 6.96791C5.85823 2.65597 7.07422 0.5 8.99984 0.5C10.9255 0.5 12.1414 2.65597 14.5734 6.96791L14.8765 7.50522C16.8974 11.0884 17.9079 12.88 16.9946 14.19C16.0814 15.5 13.8219 15.5 9.30289 15.5H8.69678C4.1778 15.5 1.91831 15.5 1.00505 14.19C0.091789 12.88 1.10226 11.0884 3.12321 7.50523L3.42626 6.96791ZM8.99984 4.04167C9.34502 4.04167 9.62484 4.32149 9.62484 4.66667V8.83333C9.62484 9.17851 9.34502 9.45833 8.99984 9.45833C8.65466 9.45833 8.37484 9.17851 8.37484 8.83333V4.66667C8.37484 4.32149 8.65466 4.04167 8.99984 4.04167ZM8.99984 12.1667C9.46008 12.1667 9.83317 11.7936 9.83317 11.3333C9.83317 10.8731 9.46008 10.5 8.99984 10.5C8.5396 10.5 8.1665 10.8731 8.1665 11.3333C8.1665 11.7936 8.5396 12.1667 8.99984 12.1667Z"
      fill="#FF3141"
    />
  </svg>
);

export default AlertIcon;
