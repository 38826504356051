'use client';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { AlertTriangle, CheckCircle, Info, XCircle } from 'lucide-react';

const MessageTypes = {
  success: { icon: CheckCircle, bgColor: '#dcfce7', textColor: '#166534', borderColor: '#4ade80' },
  error: { icon: XCircle, bgColor: '#fee2e2', textColor: '#991b1b', borderColor: '#f87171' },
  warning: { icon: AlertTriangle, bgColor: '#fef9c3', textColor: '#854d0e', borderColor: '#fde047' },
  info: { icon: Info, bgColor: '#dbeafe', textColor: '#1e40af', borderColor: '#60a5fa' },
};

let messageContainer = null;
let root = null;

const styles = {
  messageBase: {
    position: 'fixed',
    left: '50%',
    top: '16px',
    transform: 'translateX(-50%)',
    borderRadius: '6px',
    padding: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    zIndex: 9999,
    minWidth: '200px',
    maxWidth: '90%',
    animation: 'fadeInDown 0.5s, fadeOut 0.5s 2.5s',
  },
  icon: {
    width: '20px',
    height: '20px',
    marginRight: '8px',
    flexShrink: 0,
  },
  // content: {
  //   flexGrow: 1,
  // },
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '16px',
    zIndex: 9999,
  },
};

const Message = ({ type = 'info', content, duration, onClose }) => {
  const { icon: Icon, bgColor, textColor, borderColor } = MessageTypes[type];

  useEffect(() => {
    if (duration !== 0) {
      const timer = setTimeout(() => {
        onClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [duration, onClose]);

  const messageStyle = {
    ...styles.messageBase,
    backgroundColor: bgColor,
    color: textColor,
    borderColor: borderColor,
  };

  return (
    <div style={messageStyle}>
      <Icon style={styles.icon} />
      <span style={styles.content}>{content}</span>
    </div>
  );
};

const MessageContainer = ({ messages, removeMessage }) => (
  <div style={styles.container}>
    {messages.map((message) => (
      <Message key={message.id} {...message} onClose={() => removeMessage(message.id)} />
    ))}
  </div>
);

const createMessageContainer = () => {
  if (!messageContainer) {
    messageContainer = document.createElement('div');
    messageContainer.id = 'global-message-container';
    document.body.appendChild(messageContainer);
    root = createRoot(messageContainer);

    const style = document.createElement('style');
    style.textContent = `
      @keyframes fadeInDown {
        from {
          opacity: 0;
          transform: translate(-50%, -20px);
        }
        to {
          opacity: 1;
          transform: translate(-50%, 0);
        }
      }
      @keyframes fadeOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
    `;
    document.head.appendChild(style);
  }
  return messageContainer;
};

const message = (() => {
  let messages = [];

  const removeMessage = (id) => {
    const message = messages.find((msg) => msg.id === id);
    messages = messages.filter((msg) => msg.id !== id);
    renderMessages();
    if (message && message.callback) {
      message.callback();
    }
  };

  const renderMessages = () => {
    createMessageContainer();
    root.render(<MessageContainer messages={messages} removeMessage={removeMessage} />);
  };

  const addMessage = (type, content, duration, callback) => {
    const id = Date.now();
    const hideMessage = () => removeMessage(id);
    messages.push({ id, type, content, duration, callback });
    renderMessages();
    return hideMessage;
  };

  const createMessageMethod = (type) => (content, duration, callback) => {
    // 如果不传 duration，默认为 2000 毫秒
    const actualDuration = duration === undefined ? 2000 : duration;
    return addMessage(type, content, actualDuration, callback);
  };

  return {
    success: createMessageMethod('success'),
    error: createMessageMethod('error'),
    warning: createMessageMethod('warning'),
    info: createMessageMethod('info'),
  };
})();

export default message;
