// import styles from './assets/index.module.css';
import VerifySlideFixed from './components/verifySlideFixed';

const CaptchaH5 = (props: any) => {
  const { setStepNext, getResult, noCheck } = props;
  return (
    <div className="captcha-h5 flex justify-center pt-[20vh]">
      <VerifySlideFixed noCheck={noCheck} isSlideShow={true} setStepNext={setStepNext} getResult={getResult} />
    </div>
  );
};

export default CaptchaH5;
