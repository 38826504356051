import { THEME_DARK_MODE } from '@/shared/constants';
import storage from '@/shared/utils/storage';
import React, { createContext, useContext, useEffect, useState } from 'react';

type ProviderType = {
  handleChangeDarkMode?: (value: boolean) => void;
  darkMode?: boolean;
};

const Context = createContext<ProviderType>({
  darkMode: false,
});

const DarkModeProvider = ({ children }: { children: React.ReactNode }) => {
  const [darkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    setDarkMode(storage.get(THEME_DARK_MODE) || false);
  }, []);

  return (
    <Context.Provider
      value={{
        darkMode: darkMode,
        handleChangeDarkMode: (value) => {
          setDarkMode(value);
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useDarkModeProvider = () => useContext(Context);

export default DarkModeProvider;
