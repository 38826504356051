import { useLocale, useTranslations } from 'next-intl';
import Image from 'next/image';

const SizeChart = () => {
  const t = useTranslations('Product');
  const currentLocale = useLocale();

  return (
    <div className="box-border h-[708px] w-[708px] rounded-3xl bg-white p-8 max-md:h-[70vh] max-md:w-screen max-md:overflow-y-auto max-md:rounded-b-none max-md:p-3">
      <div className="mb-6 w-full text-center text-lg font-semibold leading-6 text-black">{t('sizeChart')}</div>
      <div className="mb-6 w-full text-base font-medium text-black">{t('womenClothing')}</div>
      <div className="mb-6 w-full text-base font-medium text-black">{t('stretches')}</div>
      <div className="mb-1 w-[644px] max-md:w-full">
        <Image
          src={'/images/product/sizeChartImage1.png'}
          className="h-auto w-full"
          alt="sizeChartImage1"
          width={644}
          height={16}
        />
      </div>
      <div className="mb-6 flex w-full flex-row items-center justify-between">
        <div className={`${currentLocale === 'en' ? 'w-1/4' : ''} text-sm font-medium text-[#80878E]`}>{t('no')}</div>
        <div
          className={`${currentLocale === 'en' ? 'w-1/4 pr-[48px]' : 'w-[73px]'} text-center text-sm font-medium text-black`}
        >
          {t('light')}
        </div>
        <div
          className={`${currentLocale === 'en' ? 'w-1/4 pl-[52px]' : 'w-[37px]'} text-center text-sm font-medium text-[#80878E]`}
        >
          <span>{t('Medium')}</span>
        </div>
        <div className={`${currentLocale === 'en' ? 'w-1/4 text-right' : ''} text-sm font-medium text-[#80878E]`}>
          {t('high')}
        </div>
      </div>
      <div className="mb-6 text-base font-medium text-black">{t('bodyMeasurements')}</div>
      <div className="mb-6 w-full max-md:overflow-x-auto">
        <div className="flex w-full flex-col items-start justify-start max-md:w-[644px]">
          <div className="flex w-full flex-row items-center justify-start">
            <div
              style={{
                border: '1px solid #4DBAC0',
                borderTopLeftRadius: '12px',
              }}
              className="flex h-[52px] w-[80px] flex-row items-center justify-center border-solid bg-[#c8e1e2] px-1 text-center text-xs font-semibold text-black"
            >
              {t('clothingSize')}
            </div>
            <div
              style={{
                borderTop: '1px solid #4DBAC0',
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[52px] w-[112px] flex-row items-center justify-center border-solid bg-[#c8e1e2] text-center text-xs font-semibold text-black"
            >
              {t('kazakhstan')}
            </div>
            <div
              style={{
                borderTop: '1px solid #4DBAC0',
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[52px] w-[112px] flex-row items-center justify-center border-solid bg-[#c8e1e2] text-center text-xs font-semibold text-black"
            >
              {t('china')}
            </div>
            <div
              style={{
                borderTop: '1px solid #4DBAC0',
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[52px] w-[112px] flex-row items-center justify-center border-solid bg-[#c8e1e2] text-center text-xs font-semibold text-black"
            >
              {t('europe')}
            </div>
            <div
              style={{
                borderTop: '1px solid #4DBAC0',
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[52px] w-[112px] flex-row items-center justify-center border-solid bg-[#c8e1e2] text-center text-xs font-semibold text-black"
            >
              {t('bust')} (см)
            </div>
            <div
              style={{
                borderTop: '1px solid #4DBAC0',
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
                borderTopRightRadius: '12px',
              }}
              className="border-b-1 flex h-[52px] w-[112px] flex-row items-center justify-center bg-[#c8e1e2] text-center text-xs font-semibold text-black"
            >
              {t('waist')} (см)
            </div>
          </div>
          <div className="flex w-full flex-row items-center justify-start">
            <div
              style={{
                borderLeft: '1px solid #4DBAC0',
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[80px] flex-row items-center justify-center border-solid bg-[#d4eff0] text-center text-sm font-medium text-black"
            >
              XS
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              42
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              155-160/80-84
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              32
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              75-78
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="border-b-1 flex h-[40px] w-[112px] flex-row items-center justify-center bg-white text-center text-xs text-black"
            >
              60-63
            </div>
          </div>
          <div className="flex w-full flex-row items-center justify-start">
            <div
              style={{
                borderLeft: '1px solid #4DBAC0',
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[80px] flex-row items-center justify-center border-solid bg-[#d4eff0] text-center text-sm font-medium text-black"
            >
              S
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              44
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              160-165/84-86
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              34
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              79-82
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="border-b-1 flex h-[40px] w-[112px] flex-row items-center justify-center bg-white text-center text-xs text-black"
            >
              62-66
            </div>
          </div>
          <div className="flex w-full flex-row items-center justify-start">
            <div
              style={{
                borderLeft: '1px solid #4DBAC0',
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[80px] flex-row items-center justify-center border-solid bg-[#d4eff0] text-center text-sm font-medium text-black"
            >
              M
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              46
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              165-170/88-90
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              36-38
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              83-86
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="border-b-1 flex h-[40px] w-[112px] flex-row items-center justify-center bg-white text-center text-xs text-black"
            >
              67-70
            </div>
          </div>
          <div className="flex w-full flex-row items-center justify-start">
            <div
              style={{
                borderLeft: '1px solid #4DBAC0',
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[80px] flex-row items-center justify-center border-solid bg-[#d4eff0] text-center text-sm font-medium text-black"
            >
              L
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              48
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              167-172/92-96
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              40-42
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              87-90
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="border-b-1 flex h-[40px] w-[112px] flex-row items-center justify-center bg-white text-center text-xs text-black"
            >
              71-74
            </div>
          </div>
          <div className="flex w-full flex-row items-center justify-start">
            <div
              style={{
                borderLeft: '1px solid #4DBAC0',
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[80px] flex-row items-center justify-center border-solid bg-[#d4eff0] text-center text-sm font-medium text-black"
            >
              XL
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              50
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              168-173/98-102
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              44
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              91-94
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="border-b-1 flex h-[40px] w-[112px] flex-row items-center justify-center bg-white text-center text-xs text-black"
            >
              75-78
            </div>
          </div>
          <div className="flex w-full flex-row items-center justify-start">
            <div
              style={{
                borderLeft: '1px solid #4DBAC0',
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[80px] flex-row items-center justify-center border-solid bg-[#d4eff0] text-center text-sm font-medium text-black"
            >
              XXL
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              52
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              170-176/106-110
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              46
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              95-98
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="border-b-1 flex h-[40px] w-[112px] flex-row items-center justify-center bg-white text-center text-xs text-black"
            >
              79-82
            </div>
          </div>
          <div className="flex w-full flex-row items-center justify-start">
            <div
              style={{
                borderLeft: '1px solid #4DBAC0',
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
                borderBottomLeftRadius: '12px',
              }}
              className="flex h-[40px] w-[80px] flex-row items-center justify-center border-solid bg-[#d4eff0] text-center text-sm font-medium text-black"
            >
              XXXL
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              54
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              175-180/110-114
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              48
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
              }}
              className="flex h-[40px] w-[112px] flex-row items-center justify-center border-solid bg-white text-center text-xs text-black"
            >
              99-103
            </div>
            <div
              style={{
                borderRight: '1px solid #4DBAC0',
                borderBottom: '1px solid #4DBAC0',
                borderBottomRightRadius: '12px',
              }}
              className="border-b-1 flex h-[40px] w-[112px] flex-row items-center justify-center bg-white text-center text-xs text-black"
            >
              83-86
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-row items-start justify-between">
        <Image src={'/images/product/tip_gray.png'} className="mr-2 size-6" alt="tipImage" width={24} height={24} />
        <div className="flex-1 text-xs leading-[18px] text-[#80878E]">{t('tipCopy')}</div>
      </div>
    </div>
  );
};

export default SizeChart;
