const AddIcon = ({ width = '20', height = '20' }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6286 0C10.7548 0 10.8571 0.102335 10.8571 0.228571V9.14286H19.7714C19.8977 9.14286 20 9.24519 20 9.37143V10.6286C20 10.7548 19.8977 10.8571 19.7714 10.8571H10.8571V19.7714C10.8571 19.8977 10.7548 20 10.6286 20H9.37143C9.24519 20 9.14286 19.8977 9.14286 19.7714V10.8571H0.228571C0.102335 10.8571 0 10.7548 0 10.6286V9.37143C0 9.24519 0.102335 9.14286 0.228571 9.14286H9.14286V0.228571C9.14286 0.102335 9.24519 0 9.37143 0H10.6286Z"
      fill="#999999"
    />
  </svg>
);

export default AddIcon;
