const uzbekistanFlagIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.75 8.25H23.25V15.75H0.75V8.25Z" fill="white" />
    <path d="M3.75 3H20.25C21.906 3 23.25 4.344 23.25 6V9H0.75V6C0.75 4.344 2.094 3 3.75 3Z" fill="#4498B3" />
    <path
      d="M20.25 21L3.75 21C2.094 21 0.75 19.656 0.75 18V15L23.25 15V18C23.25 19.656 21.906 21 20.25 21Z"
      fill="#55B44B"
    />
    <path d="M0.75 9H23.25V9.75H0.75V9Z" fill="#BE2A2C" />
    <path d="M0.75 14.25H23.25V15H0.75V14.25Z" fill="#BE2A2C" />
    <path
      opacity="0.15"
      d="M20.25 3H3.75C2.09325 3 0.75 4.34325 0.75 6V18C0.75 19.6567 2.09325 21 3.75 21H20.25C21.9067 21 23.25 19.6567 23.25 18V6C23.25 4.34325 21.9067 3 20.25 3ZM22.5 18C22.5 19.2405 21.4905 20.25 20.25 20.25H3.75C2.5095 20.25 1.5 19.2405 1.5 18V6C1.5 4.7595 2.5095 3.75 3.75 3.75H20.25C21.4905 3.75 22.5 4.7595 22.5 6V18Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M20.25 3.75H3.75C2.50725 3.75 1.5 4.75725 1.5 6V6.75C1.5 5.50725 2.50725 4.5 3.75 4.5H20.25C21.4928 4.5 22.5 5.50725 22.5 6.75V6C22.5 4.75725 21.4928 3.75 20.25 3.75Z"
      fill="white"
    />
    <path
      d="M5.5626 4.88242C5.46735 4.86667 5.36985 4.85767 5.2701 4.85767C4.30185 4.85767 3.5166 5.64292 3.5166 6.61117C3.5166 7.57942 4.30185 8.36467 5.2701 8.36467C5.36985 8.36467 5.46735 8.35567 5.5626 8.33992C4.73385 8.20042 4.1016 7.48042 4.1016 6.61117C4.1016 5.74192 4.73385 5.02192 5.5626 4.88242Z"
      fill="white"
    />
    <path
      d="M7.30538 7.63582L7.17263 7.22632L7.03988 7.63582H6.60938L6.95738 7.88857L6.82463 8.29732L7.17263 8.04457L7.52063 8.29732L7.38788 7.88857L7.73587 7.63582H7.30538Z"
      fill="white"
    />
    <path
      d="M9.67452 7.63582L9.54177 7.22632L9.40827 7.63582H8.97852L9.32652 7.88857L9.19377 8.29732L9.54177 8.04457L9.88977 8.29732L9.75702 7.88857L10.105 7.63582H9.67452Z"
      fill="white"
    />
    <path
      d="M9.54177 4.85767L9.40827 5.26717H8.97852L9.32652 5.51992L9.19377 5.92867L9.54177 5.67592L9.88977 5.92867L9.75702 5.51992L10.105 5.26717H9.67452L9.54177 4.85767Z"
      fill="white"
    />
  </svg>
);

export default uzbekistanFlagIcon;
