// import styles from './assets/index.module.css';
import VerifySlideFixed from './components/verifySlideFixed';

const CaptchaPage = (props: any) => {
  const { setStepNext, getResult, noCheck } = props;
  return (
    <div>
      <VerifySlideFixed noCheck={noCheck} isSlideShow={true} setStepNext={setStepNext} getResult={getResult} />
    </div>
  );
};

export default CaptchaPage;
