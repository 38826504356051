import React from 'react';

export const FacebookIcon: React.FC<{ size?: string; color?: string; className?: string }> = ({
  size = '24',
  color = 'white',
  className = '',
}) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.525 9H14V7c0-1.032.084-1.682 1.563-1.682h1.868v-3.18C16.522 2.044 15.608 2 14.693 2C11.98 2 10 3.657 10 6.699V9H7v4l3-.001V22h4v-9.003l3.066-.001L17.525 9z"
        fill={color}
      />
    </svg>
  );
};
