'use client';
import { useCallback, useMemo, useState } from 'react';
import { useTranslations } from 'next-intl';
import { useRequest } from 'ahooks';
import { getUserPublicPhoneExist } from '@/lib/services/gonggongjiekou';
import { formatPhoneNumber } from '../../utils';
import AlertIcon from '@/components/cart/AlertIcon';
import { NewLoading } from '@/components/commons';
import PhoneInputField from '../../Login/PhoneNumber/PhoneInputField';
import useCountryCodes from '../../commons/CountryCodes';
import { KZReg } from '../../Login/PhoneNumber';

export default function PhonePopup(props: any) {
  const t = useTranslations('ResetPassword');

  const { setContentNext, getResult, goBack } = props;

  const [emailOrMobile, setPhoneNumberOrEmail] = useState(''); //邮箱或者手机号
  const [isValid, setIsValid] = useState(false);
  const { CountryCodes } = useCountryCodes();
  const [selectedCountry, setSelectedCountry] = useState(CountryCodes[0]);

  const [mobileNotRegistered, setMobileNotRegistered] = useState(false);

  const handlePhoneChange = (e: any) => {
    // const value = e.target.value.replace(/\D/g, '')
    const value = e.target.value;

    const number = formatPhoneNumber(value, 10);
    console.log(number);

    let _isValid = false;
    if (number.replace(/\D/g, '').length < 10) {
      // setIsShort(true);
    }
    if (number.replace(/\D/g, '').length >= 10) {
      // _isValid = isValidPhoneNumber(`${selectedCountry.code} ${value}`, selectedCountry.flag as any);
      _isValid = KZReg.test(`${selectedCountry.code}${number}`.replace(/\D/g, ''));

      console.log(_isValid);
      if (number.replace(/\D/g, '').length > 10) {
        // 11和14位，libphonenumber-js对哈萨克手机号校验不准，姑且这样处理
        _isValid = false;
      }
    }
    setIsValid(_isValid);
    // console.log(value);

    setPhoneNumberOrEmail(number);

    // setPhoneNumberOrEmail(value.trim());
    // setMobileNotRegistered(false);
    // setEmailNotRegistered(false);
  };

  const { loading: loadingPhone, runAsync: checkExist } = useRequest(getUserPublicPhoneExist, {
    manual: true,
    onSuccess(res: { code: number; msg: string; data: boolean }) {
      console.log(res);
      if (res.data !== true) {
        setMobileNotRegistered(true);
        // message.error('Mobile number is not registered!');
      }
    },
  });

  const handleSend = useCallback(async () => {
    let isRegistered = false;

    const res = await checkExist({ phone: (selectedCountry.code + emailOrMobile).replace(/(?!^\+)\D/g, '') });
    if (res.data === true) {
      isRegistered = true;
    }

    if (isRegistered) {
      getResult((selectedCountry.code + emailOrMobile).replace(/(?!^\+)\D/g, ''));
      setContentNext();
    }
  }, [checkExist, emailOrMobile, getResult, selectedCountry.code, setContentNext]);

  const disabled = useMemo(() => {
    if (!isValid) return true;
  }, [isValid]);

  return (
    <div className="inset-0 flex min-h-fit min-w-fit items-center justify-center bg-opacity-50">
      <div className="w-[450px] rounded-3xl bg-white p-6 shadow-lg">
        <h2 className="mb-4 text-center text-lg font-semibold">{t('ask')}</h2>
        <p className="mb-4 px-8 text-center text-sm">{t('reminder1')}</p>

        <div className="w-full space-y-4">
          <PhoneInputField
            label={t('label')}
            isValid={isValid}
            value={emailOrMobile}
            onChange={handlePhoneChange}
            selectedCountry={selectedCountry}
            onCountryChange={setSelectedCountry}
          />

          {mobileNotRegistered && (
            <div className="flex items-center px-0 py-0" role="alert">
              <AlertIcon />
              <div className="ml-1 text-[12px] text-[#ff3141]"> {t('notRegistered')}</div>
            </div>
          )}

          <button
            disabled={disabled}
            className={`w-full rounded-xl bg-[#4DBAC0] py-3 text-black transition duration-150 ease-in-out hover:opacity-90 ${disabled ? 'cursor-not-allowed bg-[var(--primary-clor-gray3)] !text-[var(--theme-gray-1)]' : ''}`}
            onClick={handleSend}
          >
            {loadingPhone ? <NewLoading type="special" /> : t('send')}
          </button>

          <button
            className="w-full rounded-xl bg-[#cce8e9] py-3 text-[#4DBAC0] transition duration-150 ease-in-out hover:opacity-90"
            onClick={goBack}
          >
            {t('back')}
          </button>
        </div>
      </div>
    </div>
  );
}
