import { useState } from 'react';
import Image from 'next/image';
import { postUserPublicFileUpload } from '@/lib/services/gonggongjiekou';
import NewLoading from '../NewLoading';

const UploadImgBtn = ({ onChange }: { value?: string; onChange?: (value: string) => void }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div
      className="flex size-20 cursor-pointer flex-row items-center justify-center rounded bg-white"
      onClick={() => {
        if (loading) {
          return;
        }
        document?.getElementById('commentInput')?.click();
      }}
    >
      <input
        type="file"
        id="commentInput"
        className="hidden"
        accept="image/*"
        onChange={(e) => {
          if (loading) {
            return;
          }
          const files = e.target.files;
          if (files) {
            setLoading(true);
            postUserPublicFileUpload({}, files[0]).then((response) => {
              setLoading(false);
              if (response?.code === 0) {
                if (onChange) {
                  onChange(response?.data);
                }
              }
            });
          }
        }}
      />
      {loading ? (
        <NewLoading />
      ) : (
        <Image src={'/images/product/addIcon.svg'} width={24} height={24} className={'size-6'} alt="addIconImage" />
      )}
    </div>
  );
};

export default UploadImgBtn;
