const belarusFlagIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.75 6V15.75H23.25V6C23.25 4.34325 21.9067 3 20.25 3H3.75C2.09325 3 0.75 4.34325 0.75 6Z"
      fill="#BE2D27"
    />
    <path
      d="M20.25 21L3.75 21C2.094 21 0.75 19.656 0.75 18V15L23.25 15V18C23.25 19.656 21.906 21 20.25 21Z"
      fill="#367B37"
    />
    <path d="M3 9.75L3.75 12L3 14.25L2.25 12L3 9.75Z" fill="white" />
    <path d="M1.875 6L2.625 8.25L1.875 10.5L1.125 8.25L1.875 6Z" fill="white" />
    <path d="M4.125 6L4.875 8.25L4.125 10.5L3.375 8.25L4.125 6Z" fill="white" />
    <path d="M1.875 13.5L2.625 15.75L1.875 18L1.125 15.75L1.875 13.5Z" fill="white" />
    <path d="M4.125 13.5L4.875 15.75L4.125 18L3.375 15.75L4.125 13.5Z" fill="white" />
    <path d="M0.75 9.75V14.25L1.5 12L0.75 9.75Z" fill="white" />
    <path d="M5.25 9.75L4.5 12L5.25 14.25V9.75Z" fill="white" />
    <path
      d="M3.75 4.50008L3.26625 3.04883C3.06375 3.08183 2.86875 3.13358 2.682 3.20483L2.25 4.50008L3 6.75008L3.75 4.50008Z"
      fill="white"
    />
    <path
      d="M1.38975 4.16846C0.99525 4.67621 0.75 5.30696 0.75 5.99996V6.74996L1.5 4.49996L1.38975 4.16846Z"
      fill="white"
    />
    <path d="M5.00025 3L4.5 4.5L5.25 6.75V3H5.00025Z" fill="white" />
    <path
      d="M3.75 19.5L3.26625 20.9512C3.06375 20.9182 2.86875 20.8665 2.682 20.7952L2.25 19.5L3 17.25L3.75 19.5Z"
      fill="white"
    />
    <path d="M1.38975 19.8315C0.99525 19.3237 0.75 18.693 0.75 18V17.25L1.5 19.5L1.38975 19.8315Z" fill="white" />
    <path d="M5.00025 21L4.5 19.5L5.25 17.25V21H5.00025Z" fill="white" />
    <path
      opacity="0.15"
      d="M20.25 3H3.75C2.09325 3 0.75 4.34325 0.75 6V18C0.75 19.6567 2.09325 21 3.75 21H20.25C21.9067 21 23.25 19.6567 23.25 18V6C23.25 4.34325 21.9067 3 20.25 3ZM22.5 18C22.5 19.2405 21.4905 20.25 20.25 20.25H3.75C2.5095 20.25 1.5 19.2405 1.5 18V6C1.5 4.7595 2.5095 3.75 3.75 3.75H20.25C21.4905 3.75 22.5 4.7595 22.5 6V18Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M20.25 3.75H3.75C2.50725 3.75 1.5 4.75725 1.5 6V6.75C1.5 5.50725 2.50725 4.5 3.75 4.5H20.25C21.4928 4.5 22.5 5.50725 22.5 6.75V6C22.5 4.75725 21.4928 3.75 20.25 3.75Z"
      fill="white"
    />
  </svg>
);

export default belarusFlagIcon;
