import { useRequest } from 'ahooks';
import FacebookLogin from './FacebookLoginComponent';
import { postUserPublicWebFacebookLogin } from '@/lib/services/facebookdenglu';
import { useAuthProvider } from '@/lib/context/AuthProvider';

const Facebook = ({ getThirdUserId, setThirdPlatformType }: any) => {
  const { handleLogin } = useAuthProvider();

  const { loading, run: toLogin } = useRequest(postUserPublicWebFacebookLogin, {
    manual: true,
    onSuccess(res) {
      if (res.code === 1001) {
        const { thirdUserId } = res.data;
        if (thirdUserId) {
          getThirdUserId(thirdUserId);
          setThirdPlatformType('facebook');
        }
      }
      if (res.code === 0) {
        const { token } = res.data;
        if (token) {
          handleLogin?.(token);
          setTimeout(() => {
            window.location.reload();
          }, 200);
        }
      }
    },
  });
  return (
    <div>
      <FacebookLogin
        appId="1108996307407172"
        onLoginSuccess={(v) => {
          console.log(v);
          const { authResponse } = v;
          if (authResponse?.accessToken) {
            // return;
            toLogin({ accessToken: authResponse?.accessToken });
          }
        }}
        onLoginFailure={() => {}}
        buttonText="用Facebook登录"
        scope="public_profile,email"
        loading={loading}
      />
    </div>
  );
};

export default Facebook;
