import React, { useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import message from '@/components/commons/Message/index';
import { useTranslations } from 'next-intl';
import { postUserPublicWebLogin } from '@/lib/services/wangzhanjiekou';
import { useAuthProvider } from '@/lib/context';
import { NewLoading } from '@/components/commons';
import InputField from '@/components/commons/InputField';
import { useRouter } from 'next/router';
import EyeOff from '../../icons/EyeOff';
import Eye from '../../icons/Eye';
import EM from '@/shared/utils/EM';

const PasswordPlaceHolers = {
  ru: 'Пароль123',
  en: 'Password 123',
  kk: 'Құпия сөз 123',
};

const PasswordInput = ({
  phone,
  goback,
  setIsOpen,
  callback,
  showForgetPasswordPage,
  // loginByOtp,
}: {
  phone: string;
  goback: () => void;
  setIsOpen: (v: boolean) => void;
  callback?: () => void;
  showForgetPasswordPage: () => void;
  loginByOtp: () => void;
}) => {
  const t = useTranslations('Login');
  const router = useRouter();

  const { handleLogin } = useAuthProvider();

  const { loading, run: toLogin } = useRequest(postUserPublicWebLogin, {
    manual: true,
    async onSuccess(res) {
      if (res.data?.token) {
        setTimeout(() => {
          setIsOpen?.(false);
          callback?.();
        }, 300); // 关闭登录弹层
        await handleLogin?.(res.data?.token); // 登录后，购物车有一些列处理
        EM.emit('cart_page_initing', false);
      } else if (res.code === -1 && res.msg.includes('Account disabled')) {
        // 'Account disabled, please contact customer service'
        message.error(t('accountDisabled'), 2000);
      } else {
        message.error(res.msg || t('failed'), 2000);
      }
    },
  });

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = (field: string) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('--', { v: e.target.value.trim() });
    setPassword(e.target.value.trim());
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (phone && password) {
      EM.emit('cart_page_initing', true);
      toLogin({
        mobile: phone.replace(/(?!^\+)\D/g, ''),
        password,
      });
    }
  };

  const disabled = useMemo(() => {
    return !password || loading;
  }, [loading, password]);

  return (
    <div className="logreg-inputs relative inset-0 flex min-w-fit items-center justify-center bg-opacity-50">
      <div className="w-[430px] rounded-3xl bg-white p-6 shadow-lg">
        <h2 className="mb-4 text-center text-2xl font-semibold">{t('PasswordInput')}</h2>
        <form onSubmit={handleSubmit} className="w-full space-y-4">
          <div className="relative">
            <InputField
              label={t('Password')}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              placeholder={PasswordPlaceHolers[router.locale as 'en' | 'ru' | 'kk']}
              id="password"
              name="password"
              labelColor="#000"
            />

            <button
              type="button"
              onClick={() => togglePasswordVisibility('password')}
              className="absolute right-4 top-[50%] -translate-y-[50%] text-[#9ba1a8]"
            >
              {showPassword ? <Eye /> : <EyeOff />}
            </button>
          </div>

          <div onClick={showForgetPasswordPage} className="mx-4 flex-shrink cursor-pointer text-center text-[#1890FF]">
            {t('ForgetPasswordAsk')}
          </div>

          {/* <div onClick={loginByOtp} className="mx-4 flex-shrink cursor-pointer pb-2 text-center text-md text-[#1890FF]">
            {t('useVerycode')}
          </div> */}

          <button
            type="submit"
            disabled={disabled}
            className={`w-full rounded-xl bg-[#4DBAC0] py-3 text-lg font-medium text-black transition duration-150 ease-in-out hover:opacity-90 ${disabled ? 'bg-[var(--primary-clor-gray3)] !text-[var(--theme-gray-1)]' : ''}`}
          >
            {loading ? <NewLoading type="special" /> : t('enter')}
          </button>

          <button
            type="button"
            className="mt-4 w-full rounded-xl bg-[#cce8e9] py-3 text-lg font-medium text-[#4DBAC0] transition duration-150 ease-in-out hover:opacity-90"
            onClick={goback}
          >
            {t('back')}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordInput;
