const RussiaFlagIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.75 8.25H23.25V15.75H0.75V8.25Z" fill="#1435A1" />
    <path d="M3.75 3H20.25C21.906 3 23.25 4.344 23.25 6V9H0.75V6C0.75 4.344 2.094 3 3.75 3Z" fill="white" />
    <path
      d="M20.25 21L3.75 21C2.094 21 0.75 19.656 0.75 18V15L23.25 15V18C23.25 19.656 21.906 21 20.25 21Z"
      fill="#C53A28"
    />
    <path
      opacity="0.15"
      d="M20.25 3H3.75C2.09325 3 0.75 4.34325 0.75 6V18C0.75 19.6567 2.09325 21 3.75 21H20.25C21.9067 21 23.25 19.6567 23.25 18V6C23.25 4.34325 21.9067 3 20.25 3ZM22.5 18C22.5 19.2405 21.4905 20.25 20.25 20.25H3.75C2.5095 20.25 1.5 19.2405 1.5 18V6C1.5 4.7595 2.5095 3.75 3.75 3.75H20.25C21.4905 3.75 22.5 4.7595 22.5 6V18Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M20.25 3.75H3.75C2.50725 3.75 1.5 4.75725 1.5 6V6.75C1.5 5.50725 2.50725 4.5 3.75 4.5H20.25C21.4928 4.5 22.5 5.50725 22.5 6.75V6C22.5 4.75725 21.4928 3.75 20.25 3.75Z"
      fill="white"
    />
  </svg>
);

export default RussiaFlagIcon;
