import { useTranslations } from 'next-intl';
import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useRequest } from 'ahooks';
import { getUserPublicPhoneExist } from '@/lib/services/gonggongjiekou';
import AlertIcon from '@/components/cart/AlertIcon';
import { NewLoading } from '@/components/commons';
import { postUserPublicWebGoogleLogin } from '@/lib/services/googledenglu';
import { useAuthProvider } from '@/lib/context';
import storage from '@/shared/utils/storage';
import CustomCheckbox from '@/components/cart/CustomCheckbox';
import { Timeout } from 'ahooks/lib/useRequest/src/types';
import useCountryCodes from '@/components/logreg/commons/CountryCodes';
import PhoneInputField from '@/components/logreg/Login/PhoneNumber/PhoneInputField';
import { formatPhoneNumber } from '@/components/logreg/utils';
import CustomModalForPolicyH5 from '@/components/commons/CustomModalForPolicyH5';
import GoogleLoginButton from '@/components/logreg/Login/PhoneNumber/GoogleLogin/GoogleLogin';
import FacebookLogin from '@/components/logreg/Login/PhoneNumber/FacebookLogin';
import PolicyAndTerm from '@/components/logreg/Login/PhoneNumber/PolicyAndTerm';
import HeaderBackH5 from '../../common/HeaderBackH5';

// 俄罗斯 号码格式 区号+7位： (800) 555-35-35  区号+7位

export const KZReg = /^(\+[78]|[78])[6|7|8|9]([0-9]{2}[0-9]{7})$/;

const PhoneNumberInputH5 = function (props: any) {
  const t = useTranslations('Login');
  const { getResult, setRegisttarionPage, setContentNext, getThirdUserId, thirdUserId, setThirdPlatformType, goBack } =
    props;

  const { handleLogin } = useAuthProvider();

  const [termsChecked, setTermsChecked] = useState(false);
  const [isTermLayerOpen, setTermLayerOpen] = useState<boolean>(false);

  const [isNotRegistered, setIsNotRegistered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isInputing, setIsInputing] = useState(false);

  const [isExist, setExist] = useState(false);
  const { loading: checkLoading, run: checkExist } = useRequest(getUserPublicPhoneExist, {
    manual: true,
    onSuccess(res: { code: number; msg: string; data: boolean }) {
      // console.log(res);
      if (res?.data === true) {
        //存在,提示该手机号已存在
        console.log(selectedCountry.code + phoneNumber);
        setIsNotRegistered(false);
        setExist(true);

        getResult(selectedCountry.code + phoneNumber);
        setTimeout(() => {
          // setContentNext();
          setLoading(false);
        }, 200);
      } else {
        setIsNotRegistered(true);
        // if (termsChecked) {
        //   setTimeout(() => {
        //     setRegisttarionPage({
        //       countryCode: selectedCountry.code,
        //       phoneNumber: phoneNumber,
        //       country: selectedCountry.abbr,
        //     });
        //     setLoading(false);
        //   }, 1500);
        // } else {
        //   setLoading(false);
        // }
      }
    },
  });

  const { CountryCodes } = useCountryCodes();

  const [selectedCountry, setSelectedCountry] = useState(CountryCodes[0]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isShort, setIsShort] = useState(true);

  const handlePhoneChange = useCallback(
    (e: any) => {
      // const value = e.target.value.replace(/\D/g, '')
      setIsInputing(true);
      setExist(false);
      setIsNotRegistered(false);
      setIsShort(false);
      const value = e.target.value;

      const number = formatPhoneNumber(value, 10);
      console.log(number);

      let _isValid = false;
      if (number.replace(/\D/g, '').length < 10) {
        setIsShort(true);
      }
      if (number.replace(/\D/g, '').length >= 10) {
        // _isValid = isValidPhoneNumber(`${selectedCountry.code} ${value}`, selectedCountry.flag as any);
        _isValid = KZReg.test(`${selectedCountry.code}${number}`.replace(/\D/g, ''));

        console.log(_isValid);
        if (number.replace(/\D/g, '').length > 10) {
          // 11和14位，libphonenumber-js对哈萨克手机号校验不准，姑且这样处理
          _isValid = false;
        }
      }
      setIsValid(_isValid);
      // console.log(value);

      setPhoneNumber(number);
    },
    [selectedCountry.code], // selectedCountry.flag
  );

  // 处理自动检测手机号
  const timerRef = useRef<Timeout | null>(null);
  useEffect(() => {
    console.log(isValid);

    if (timerRef.current) {
      // timerRef.current=null // bug
      clearTimeout(timerRef.current); // 必须用 clearTimeout
    }
    timerRef.current = setTimeout(() => {
      if (isValid) {
        checkExist({ phone: (selectedCountry.code + phoneNumber).replace(/(?!^\+)\D/g, '') });
        setIsInputing(false);
      }
      timerRef.current = null;
    }, 1000);
  }, [checkExist, isValid, phoneNumber, selectedCountry.code]);

  const initRef = useRef(0);

  const disabled = useMemo(() => {
    if (isInputing) return true;
    if (loading || checkLoading) return true;
    if (!isValid || isShort) return true;
    if (isNotRegistered) {
      return !termsChecked;
    }
  }, [checkLoading, isNotRegistered, isShort, isValid, loading, termsChecked, isInputing]);

  const [contentType, setTermContent] = useState('TermsOfUse'); // 用于控制弹窗展示哪个协议内容: TermsOfUse PrivacyPolicy

  return (
    <div className="logreg-inputs inset-0 flex min-h-fit min-w-fit items-center justify-center bg-opacity-50">
      <div className="h-[100vh] w-full rounded-none bg-white p-6 shadow-lg">
        <HeaderBackH5 goBack={goBack} />
        <h2 className="mb-4 text-center text-[18px] font-semibold">{t('entrance')}</h2>
        <div className="w-full space-y-4">
          <PhoneInputField
            label={t('yourphonenumber')}
            isValid={isValid}
            value={phoneNumber}
            onChange={handlePhoneChange}
            selectedCountry={selectedCountry}
            onCountryChange={setSelectedCountry}
          />

          {phoneNumber && !isValid && (
            <div className="flex items-center px-0 py-0" role="alert">
              <AlertIcon />
              <div className="red ml-1 text-[13px]"> {t('InvalidPhoneNumber')}</div>
            </div>
          )}
          {isNotRegistered && !termsChecked && (
            <div className="flex items-start px-0 py-0" role="alert">
              <span className="mt-1">
                <AlertIcon />
              </span>
              <div className="red ml-1 text-[13px]"> {t('toAcceptTerms')}</div>
            </div>
          )}

          <button
            className={`w-full rounded-xl py-3 text-[16px] font-medium transition duration-150 ease-in-out hover:opacity-90 ${disabled ? 'bg-[var(--primary-clor-gray3)] !text-[var(--theme-gray-1)]' : 'bg-[#4DBAC0] text-black'}`}
            disabled={disabled}
            onClick={() => {
              if (isExist) {
                setTimeout(() => {
                  setContentNext();
                  setLoading(false);
                }, 0);
              } else {
                if (termsChecked) {
                  setTimeout(() => {
                    setRegisttarionPage({
                      countryCode: selectedCountry.code,
                      phoneNumber: phoneNumber,
                      country: selectedCountry.abbr,
                    });
                    setLoading(false);
                  }, 0);
                } else {
                  setLoading(false);
                }
              }
            }}
          >
            {loading || checkLoading ? <NewLoading type="special" /> : t('enter')}
          </button>

          {thirdUserId && (
            <div className="pb-2">
              <p className="text-[14px] text-[#ff3141]">{t('googleLoginPhoneInput')}</p>
            </div>
          )}

          <>
            <div className="my-4 flex items-center">
              <div className="flex-grow border-t border-[#4DBAC0]"></div>
              <span className="mx-4 flex-shrink text-[12px] font-medium text-black">{t('or')}</span>
              <div className="flex-grow border-t border-[#4DBAC0]"></div>
            </div>

            <div className="space-y-4">
              <GoogleLoginButton
                onCancel={() => {}}
                onSuccess={(response) => {
                  setLoading(true);
                  postUserPublicWebGoogleLogin({ idToken: response.credential })
                    .then((res) => {
                      if (res.code === 1001) {
                        // res.data.thirdUserId
                        if (res.data.thirdUserId) {
                          getThirdUserId(res.data.thirdUserId);
                          if (res.data.email) {
                            storage.set('google-email-temp', res.data.email);
                          }
                        }
                      }
                      if (res.data.token) {
                        handleLogin?.(res.data?.token);
                        window.location.reload();
                      }
                      setLoading(false);
                    })
                    .catch(() => {
                      // message.error(t('failed'));
                      setLoading(false);
                    });
                }}
                loadingOuter={loading}
                disabled={!!thirdUserId}
              />

              <FacebookLogin
                getThirdUserId={(v: string) => {
                  getThirdUserId(v);
                }}
                setThirdPlatformType={setThirdPlatformType}
              />
            </div>
          </>

          {!isExist && (
            <div className="flex cursor-pointer items-start justify-start">
              <CustomCheckbox
                checked={termsChecked}
                onChange={() => {
                  if (initRef.current === 0) {
                    setTermLayerOpen(true);
                    initRef.current = 1;
                  } else {
                    setTermsChecked(!termsChecked);
                  }
                }}
                label=""
              />
              <label className="flex select-none flex-wrap justify-start">
                <span className="my-0 mr-2">{t('termTitle1')}</span>
                <a
                  className="my-0 mr-2 cursor-pointer text-[#1890FF] hover:underline"
                  onClick={() => {
                    setTermLayerOpen(true);
                    setTermContent('TermsOfUse');
                  }}
                >
                  {t('termTitle2')}
                </a>
                <span className="my-0 mr-2">{t('termTitle3')}</span>
                <a
                  className="my-0 cursor-pointer text-[#1890FF] hover:underline"
                  onClick={() => {
                    setTermLayerOpen(true);
                    setTermContent('PrivacyPolicy');
                  }}
                >
                  {t('termTitle4')}
                </a>
              </label>
            </div>
          )}
        </div>

        <CustomModalForPolicyH5
          triggerIcon={<></>}
          isOpen={isTermLayerOpen}
          setIsOpen={setTermLayerOpen}
          callback={() => {
            // 关闭弹层的回调
            // setContent('phone_number_input');
          }}
        >
          <PolicyAndTerm
            contentType={contentType}
            setTermContent={setTermContent}
            termsChecked={termsChecked}
            setTermsChecked={setTermsChecked}
            close={() => setTermLayerOpen(false)}
          />
        </CustomModalForPolicyH5>
      </div>
    </div>
  );
};

export default PhoneNumberInputH5;
