import useMobileDetect from '@/lib/hooks/useMobileDetect';
import { CheckCircle } from 'lucide-react';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';

// 倒计时
// 弹到首页
const DoubleVerifySuccess = function () {
  const t = useTranslations('TwoFactorAuth');
  const { isMobile } = useMobileDetect(); // 要不得
  console.log({ isMobile });
  const router = useRouter();

  // 使用ref追踪是否已经执行过跳转
  const hasRedirected = useRef(false);
  // 使用state来追踪isMobile是否已经稳定
  const [isMobileStable, setIsMobileStable] = useState(false);

  // 第一个useEffect用来处理isMobile的稳定性
  useEffect(() => {
    // 给一个短暂的延时，确保isMobile的值已经稳定
    const timer = setTimeout(() => {
      setIsMobileStable(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  // 第二个useEffect处理实际的路由跳转逻辑
  useEffect(() => {
    // 只在isMobile稳定且还未执行过跳转时执行
    if (isMobileStable && !hasRedirected.current) {
      hasRedirected.current = true;

      setTimeout(() => {
        if (isMobile) {
          router.replace('/react_native_email_verify');
        } else {
          // router.replace('/');
        }
      }, 600);
    }
  }, [isMobileStable, isMobile, router]);

  return (
    <div className="mx-auto w-[350px] rounded-lg bg-white p-6 text-center shadow-md">
      <div className="mb-4 flex justify-center">
        <CheckCircle className="mb-2 h-16 w-16 text-[#6DD23D]" />
      </div>

      <h2 className="mb-2 text-xl font-semibold">{t('registerSuccess')}</h2>
      <p className="mb-6 text-black">{t('toStart')}</p>

      <button
        onClick={() => {
          window.location.reload();
        }}
        className="w-full cursor-pointer rounded-xl bg-[#5dbab1] py-2 text-black transition-colors"
      >
        {t('continue')}
      </button>
    </div>
  );
};

export default DoubleVerifySuccess;
