import React, { createContext, useContext, useMemo } from 'react';
import { useRequest } from 'ahooks';
import { getProductWebProductCollectTotal } from '../services/zhuye';

type ProviderType = {
  handleRefreshColelectTotal?: () => void;
  collectTotal?: number;
};

const Context = createContext<ProviderType>({
  collectTotal: 0,
});

const CollectProvider = ({ children, value }: { children: React.ReactNode; value: any }) => {
  const isLogin = useMemo(() => value?.token, [value]);

  //收藏数量 -- 需要登录
  const { data: data, refresh } = useRequest(getProductWebProductCollectTotal, {
    manual: !isLogin,
    refreshDeps: [isLogin],
  });

  return (
    <Context.Provider
      value={{
        collectTotal: (data?.data ?? 0) as any,
        handleRefreshColelectTotal: refresh,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useCollectProvider = () => useContext(Context);

export default CollectProvider;
