import requestCaptcha from './axios';

export async function getPicture(params: any) {
  return requestCaptcha<{
    repCode: string;
    repData: {
      originalImageBase64: string;
      point: { x: number; y: number };
      jigsawImageBase64: string;
      token: string;
      secretKey: string;
      result: boolean;
      opAdmin: boolean;
    };
    success: boolean;
    error: boolean;
  }>('/user/public/captcha/get', {
    method: 'POST',
    data: params,
  });
}
export async function reqCheck(params: any) {
  return requestCaptcha<{
    repCode: string;
    repData: { captchaType: string; pointJson: string; token: string; result: boolean; ts: number };
    success: boolean;
    ticket: string;
  }>('/user/public/captcha/check', {
    method: 'POST',
    data: params,
  });
}
