'use client';

import { useTranslations } from 'next-intl';
import Image from 'next/image';

const BannerNotification = ({ onClose }: { onClose?: () => void }) => {
  const t = useTranslations();

  return (
    <div className="relative flex h-9 w-full flex-row items-center justify-center bg-[#fdb95b] text-center text-sm font-medium text-black max-md:box-border max-md:px-8 max-md:text-xs">
      {t('Home.notification')}
      <Image
        className="absolute right-6 size-5 cursor-pointer max-md:right-3"
        onClick={onClose}
        width={20}
        height={20}
        src={'/images/home/close.png'}
        alt="close"
      />
    </div>
  );
};

export default BannerNotification;
