import { NewLoading } from '@/components/commons';
import React, { useState, useEffect, useRef } from 'react';
import { messageUtil } from '@/shared/utils/messageUtil';

const AutoHeightIframe = ({
  src,
  title = 'Iframe content',
  width = '100%',
  className = '',
  // onLoad,
  onError,
  sandbox = 'allow-same-origin allow-scripts',
}: any) => {
  const [height, setHeight] = useState<number>(0);
  const iframeRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  // const heightSetTimer = useRef<any>();

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    const handleResize = () => {
      console.info('resize', iframeRef.current);
      try {
        // 获取iframe内容文档的高度
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        const newHeight = iframeDoc.documentElement.scrollHeight;
        console.info(iframeDoc, newHeight);

        setHeight(newHeight);
        // messageUtil.sendToParent('article_loading', false);
        console.info('article_loading finish');
      } catch (e: any) {
        console.warn('Cannot access iframe content - possible cross-origin restriction', e);
        console.info('Cannot access iframe content - possible cross-origin restriction', e);
      }
    };

    // if (height === 0) {
    //   // 测试，加定时器解决生产环境height设置失败
    //   heightSetTimer.current = setInterval(handleResize, 2000);
    //   console.info('setInterval---');
    // } else {
    //   clearInterval(heightSetTimer.current);
    // }

    const handleLoad = () => {
      console.log('handleLoad');
      console.info('handleLoad---');

      setIsLoading(false);
      handleResize();

      try {
        // 监听iframe内容变化
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
        const resizeObserver = new ResizeObserver(() => {
          handleResize();
          console.info('iframe内容变化');
        });
        resizeObserver.observe(iframeDoc.body);

        return () => resizeObserver.disconnect();
      } catch (e: any) {
        console.warn('Cannot access iframe content - possible cross-origin restriction', e);
      }
    };

    iframe.addEventListener('load', handleLoad);

    // 添加消息监听器，用于跨域情况
    const handleMessage = (event: any) => {
      // 确保消息来源可信
      if (event.origin !== new URL(src).origin) return;

      if (event.data.type === 'setHeight') {
        setHeight(event.data.height);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      iframe.removeEventListener('load', handleLoad);
      window.removeEventListener('message', handleMessage);
    };
  }, [src]);

  // .replace(/www\./i, '')
  return (
    <div className="relative w-full">
      {isLoading && (
        <div className="absolute inset-0 top-20 flex items-center justify-center bg-gray-50">
          <NewLoading />
        </div>
      )}

      <iframe
        ref={iframeRef}
        src={src}
        title={title}
        width={width}
        height={height}
        className={`border-0 ${className}`}
        onError={onError}
        sandbox={sandbox}
        // style={{ minHeight: '100px' }}
      />
    </div>
  );
};

export default AutoHeightIframe;
