import { create } from 'zustand';

interface RouteParamsState {
  baseRoute: string;
  setbaseRoute: (params: string) => void;
}

const useIntelBaseRoute = create<RouteParamsState>((set) => ({
  baseRoute: '/ru',
  setbaseRoute: (baseRoute: string) => set({ baseRoute }),
}));

export default useIntelBaseRoute;
