import React, { ReactNode, useState } from 'react';
import AlertIcon from '@/components/cart/AlertIcon';

const CustomFormItem = ({ children, fieldState = {} }: { children: ReactNode; fieldState?: any }) => {
  const { error } = fieldState;
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div>
      <div
        className={`rounded-xl border-[1px] bg-[#F5F6F6] px-3 py-1 ${
          error ? 'border-red-400' : isFocused ? 'border-[#4DBAC0]' : 'border-[var(--theme-color-blue2)]'
        }`}
      >
        {React.Children.map(children, (child) =>
          React.cloneElement(child as React.ReactElement, {
            onFocus: handleFocus,
            onBlur: handleBlur,
          }),
        )}
      </div>
      {error?.message && (
        <div className="flex items-start px-0 py-0" role="alert">
          <div className="pt-1">
            <AlertIcon />
          </div>
          <div className="ml-1 text-[13px] text-[#dc2626]"> {error.message}</div>
        </div>
      )}
    </div>
  );
};

export default CustomFormItem;
