import axios, { AxiosRequestConfig } from 'axios';
// import getConfig from 'next/config';
import storage from '@/shared/utils/storage';
import { USER_LOGIN_TOKEN } from '@/shared/constants';

// const { publicRuntimeConfig } = getConfig();

const instance = axios.create({
  // baseURL: publicRuntimeConfig.NEXT_PUBLIC_API_HOST,
  baseURL: ['development', 'review'].includes(process.env.NEXT_PUBLIC_ENV as string)
    ? process.env.NEXT_PUBLIC_API_HOST // ? '/wk' 2024.11.19测试，开发环境，取消前端跨域配置ok
    : process.env.NEXT_PUBLIC_API_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 20000,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (typeof window === 'object') {
      // message.destroy();
      // message.error('Sorry, something went wrong. Please try again.');
    }
    return Promise.reject(error);
  },
);

instance.interceptors.request.use(
  (config) => {
    const token = storage.get(USER_LOGIN_TOKEN);
    if (token) {
      config.headers['Authorization'] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export function updateAuthorization(token: string) {
  instance.defaults.headers.common['Authorization'] = `${token}`;
}

export function getAuthorization() {
  const token = storage.get(USER_LOGIN_TOKEN);
  return token ? `${token}` : null;
}

const requestCaptcha = function <T = any>(
  url: string,
  opts: AxiosRequestConfig & { requestType?: 'json' | 'form' } = { method: 'GET' },
) {
  const options = { ...opts };
  // 兼容from data文件上传的情况
  const { requestType, ...rest } = opts;
  if (requestType === 'form') {
    options.headers = {
      ...(rest.headers || {}),
      'Content-Type': 'multipart/form-data',
    };
  }
  return new Promise<T>((resolve, reject) => {
    instance
      .request<T>({ url, ...opts })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export default requestCaptcha;
