import React, { createContext, useContext, useMemo } from 'react';
import { useRequest } from 'ahooks';
import { getUserWebMessageOpenApiNewTotal } from '../services/zhuye';

type ProviderType = {
  handleRefreshNotificationTotal?: () => void;
  notificationTotal?: number;
};

const Context = createContext<ProviderType>({
  notificationTotal: 0,
});

const NotificationProvider = ({ children, value }: { children: React.ReactNode; value: any }) => {
  const isLogin = useMemo(() => value?.token, [value]);

  //新消息数量 -- 需要登录
  const { data, refresh } = useRequest(getUserWebMessageOpenApiNewTotal, {
    manual: !isLogin,
    refreshDeps: [isLogin],
    cacheKey: 'getUserWebMessageOpenApiNewTotalCacheKey',
  });

  return (
    <Context.Provider
      value={{
        notificationTotal: (data?.data ?? 0) as any,
        handleRefreshNotificationTotal: refresh,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useNotificationProvider = () => useContext(Context);

export default NotificationProvider;
