import { useEffect, useState } from 'react';

interface DetectionResult {
  isMobile: boolean;
  isDesktop: boolean;
  isIOS: boolean;
  isAndroid: boolean;
  isWechat: boolean;
}

const useMobileDetect = (): DetectionResult => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const detect = (): boolean => {
      if (typeof window === 'undefined') return false;

      const userAgent: string = navigator.userAgent || navigator.vendor || (window as any).opera || '';

      // 检查是否为移动设备的正则表达式
      const mobileRegex: RegExp = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;

      // 方法2: 通过屏幕宽度检测
      const isNarrowScreen: boolean = window.innerWidth <= 768;

      // 方法3: 通过触摸能力检测
      const hasTouchScreen: boolean =
        'ontouchstart' in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0;
      console.log(mobileRegex.test(userAgent), isNarrowScreen, hasTouchScreen);
      // 综合判断：符合任一条件即认为是移动设备
      return mobileRegex.test(userAgent) || (isNarrowScreen && hasTouchScreen);
    };

    // 初始检测
    setIsMobile(detect());

    // 监听窗口大小变化
    const handleResize = (): void => {
      setIsMobile(detect());
    };

    window.addEventListener('resize', handleResize);

    // 清理监听器
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const checkUserAgent = (pattern: RegExp): boolean => {
    if (typeof navigator === 'undefined') return false;
    return pattern.test(navigator.userAgent);
  };

  return {
    isMobile,
    isDesktop: !isMobile,
    isIOS: checkUserAgent(/iPhone|iPad|iPod/i),
    isAndroid: checkUserAgent(/Android/i),
    isWechat: checkUserAgent(/MicroMessenger/i),
  };
};

export type { DetectionResult };
export default useMobileDetect;
