// @ts-ignore
/* eslint-disable */
import request from '@/shared/utils/axios';

/** facebook登录 POST /user/public/web/facebook/login */
export async function postUserPublicWebFacebookLogin(
  body: {
    /** client端获取到的用户授权token */
    accessToken: string;
  },
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: { token: string; thirdUserId: string; email: string } }>(
    '/user/public/web/facebook/login',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: body,
      ...(options || {}),
    },
  );
}
