'use client';
import { useState } from 'react';
import { useTranslations } from 'next-intl';
import InputField from '@/components/commons/InputField';

const OTPLogin = (props: any) => {
  const t = useTranslations('OTPLogin');

  const { setContentNext, getResult, goBack } = props;

  const [phoneNumber, setPhoneNumber] = useState(''); //邮箱或者手机号

  const handlePhoneChange = (e: any) => {
    // const value = e.target.value.replace(/\D/g, '')
    const value = e.target.value;

    setPhoneNumber(value);
  };

  return (
    <div className="inset-0 flex min-h-fit min-w-fit items-center justify-center bg-opacity-50">
      <div className="w-[450px] rounded-3xl bg-white p-6 shadow-lg">
        <h2 className="mb-4 text-center text-lg font-semibold">{t('ask')}</h2>

        <div className="w-full space-y-4">
          <InputField
            label={t('label')}
            placeholder="example@email.com"
            value={phoneNumber}
            onChange={handlePhoneChange}
            withClearIcon={false}
            labelColor="#000"
          />

          <button
            disabled={!phoneNumber}
            className={`w-full rounded-xl bg-[#4DBAC0] py-3 text-black transition duration-150 ease-in-out hover:opacity-90 ${!phoneNumber ? 'cursor-not-allowed bg-[var(--primary-clor-gray3)] !text-[var(--theme-gray-1)]' : ''}`}
            onClick={() => {
              getResult(phoneNumber);
              setContentNext();
            }}
          >
            {t('send')}
          </button>

          <button
            className="w-full rounded-xl bg-[#cce8e9] py-3 text-[#4DBAC0] transition duration-150 ease-in-out hover:opacity-90"
            onClick={goBack}
          >
            {t('back')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OTPLogin;
