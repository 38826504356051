import { createContext, MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, isNull } from 'lodash';
import { useLocalStorageState } from 'ahooks';
import { getAnonymousUser } from '@/shared/utils/tools';
import {
  getProductWebShoppingCartList,
  putProductWebShoppingCart,
  postProductWebShoppingCartAdd,
} from '@/lib/services/gouwuche';
import { getUserWebCustomerProfile } from '@/lib/services/gerenzhongxin';
import { useAuthorizedUserShoppingCartCache } from '@/lib/hooks/useAuthorizedUserShoppingCartCache';
import { useRouter } from 'next/router';
import { uniqBy } from 'lodash';
import storage from '@/shared/utils/storage';
import { CART_PAGE_SYNCH_TIME } from '@/shared/constants';

type ProviderType = {
  /**
   * 登录后的购物车同步
   * issue: https://gitlab.parsec.com.cn/umay/website/-/issues/14
   *
   * 需求描述:
   * 在登录后，需要访问 cart-unloginUser 缓存空间，如果有数据，将此本地缓存中的所有商品，都放入到云端购物车，在执行完成后，无论成功与否，
   * 都清空 cart-unloginUser 缓存。结束后，调用 #13  中的方法，同步云端购物车到本地缓存。
   *
   * 实现细节:
   * 1、添加商品到购物车的接口为 /product/web/shopping/cart/add
   * 2、购物车中的商品数据结构为 CartItem。
   */

  shoppingCartSynchronizationAfterLogin?: () => void;
  cacheShoppingCartData: Array<API.WebShoppingCartItemVo> | undefined;
  shoppingCartCount: number;
  handleAddProductToShoppingCart: (product: API.WebShoppingCartItemVo) => Promise<void>;
  handleUpdateProductsToShoppingCart: (products: API.WebShoppingCartItemVo[]) => Promise<void>;
  cleanCacheShoppingCartData: () => void;
  deleteShoppingCartItem: (params: API.postProductWebShoppingCartRemoveParams) => void;
  synchronizeShoppingCartCacheData?: () => Promise<void>;
  onlyForLogin: MutableRefObject<boolean>;
  isCartPageLoading: boolean;
};

const Context = createContext<ProviderType>({
  handleAddProductToShoppingCart(product: API.WebShoppingCartItemVo): Promise<void> {
    console.log(product);
    return Promise.resolve(void 0);
  },
  handleUpdateProductsToShoppingCart(products: any[]) {
    console.log(products);
    return Promise.resolve(void 0);
  },
  cacheShoppingCartData: [],
  cleanCacheShoppingCartData: () => {},
  shoppingCartCount: 0,
  deleteShoppingCartItem: (params: API.postProductWebShoppingCartRemoveParams) => {
    console.log(params);
  },
  // onlyForLogin: false,
  isCartPageLoading: true,
} as any);

const CartProvider = (props: any) => {
  const { children, value } = props;
  console.log(value);

  const { pathname } = useRouter();

  const isLogin = useMemo(() => value?.token, [value]);
  const onlyForLogin = useRef(false);

  const [isCartPageLoading, setCartLoading] = useState(true);

  // 授权用户购物车缓存数据
  const { authorizedUserShoppingCartCacheData, setAuthorizedUserShoppingCartCacheData } =
    useAuthorizedUserShoppingCartCache(value?.token);

  // console.log({ authorizedUserShoppingCartCacheData });

  // 匿名用户购物车缓存数据
  const [anonymousUserShoppingCartCacheData, setAnonymousUserShoppingCartCacheData] = useLocalStorageState<
    Array<API.WebShoppingCartItemVo>
  >(`${process.env.NEXT_PUBLIC_STORAGE_PREFIX}-cart-${getAnonymousUser()}`, {
    defaultValue: [],
    listenStorageChange: true,
  });

  /**
   * 购物车缓存同步 （将云端的数据同步到本地，本地缓存以token为key）
   * issue: https://gitlab.parsec.com.cn/umay/website/-/issues/13
   *
   * 需求描述:
   * 在页面的Header组件中设计一个同步购物车缓存的机制，任何情况下，只要重新渲染了Header组件，都同步一次云端购物车到本地缓存。
   *
   * 细节约定:
   * 1、取当前用户的Token作为Key，cart-[token] 这个空间，为购物车数据。比如 cart-23sdfsqqqqwjkasdjppooiii00001212
   * 2、购物车云端的接口为/product/web/shopping/cart/list。
   * 3、如果用户没有登录（没有取到Token），则无需执行此方法。。
   * 4、购物车数据结构为 CartItem。
   *
   * 同步云端购物车数据到本地 的时机只有页面跳转或者刷新的时候才会触发
   */
  const synchronizeShoppingCartCacheData = useCallback(async () => {
    if (onlyForLogin.current) return; // 刚登录的时候，做shoppingCartSynchronizationAfterLogin处理

    const productWebShoppingCartList = await getProductWebShoppingCartList();
    if (productWebShoppingCartList.code === 0) {
      console.log(productWebShoppingCartList.data); // 排查后端是否会重复商品
      setCartLoading(false);
    } else {
      setCartLoading(false);
    }
    if (!isEmpty(productWebShoppingCartList) && !isNull(productWebShoppingCartList)) {
      setAuthorizedUserShoppingCartCacheData(uniqBy([...(productWebShoppingCartList?.data || [])], 'id') ?? []);
      setAnonymousUserShoppingCartCacheData(undefined);
    }
  }, [setAnonymousUserShoppingCartCacheData, setAuthorizedUserShoppingCartCacheData]);

  /**
   * 登录后的购物车同步
   */
  const shoppingCartSynchronizationAfterLogin = useCallback(async () => {
    onlyForLogin.current = true;
    if (anonymousUserShoppingCartCacheData) {
      // alert('登录后，将匿名用户的缓存数据同步到 cart-token 的缓存中');
      // console.log({ anonymousUserShoppingCartCacheData });

      // 先获取云端数据
      const productWebShoppingCartList = await getProductWebShoppingCartList();

      // 这里应该做云端数据和本地匿名数据的合并
      const res = uniqBy([...anonymousUserShoppingCartCacheData, ...(productWebShoppingCartList?.data || [])], 'id');
      console.log(res); // 去重 id (不可以是productId)

      // 将匿名用户的缓存数据同步到 cart-token 的缓存中
      // setAuthorizedUserShoppingCartCacheData(res);
      const response = await putProductWebShoppingCart(res);
      if (response.code === 0) {
        console.log('同步本地到云端');
      }

      // 清空匿名用户的购物车缓存数据
      setAnonymousUserShoppingCartCacheData(undefined);

      onlyForLogin.current = false;
      return true;
    }
  }, [anonymousUserShoppingCartCacheData, setAnonymousUserShoppingCartCacheData]);

  /**
   * 添加商品到购物车
   * @param product 商品信息
   * 说明：如果用户已登录，则直接调用接口添加商品到购物车，否则将商品添加到本地缓存中
   */
  const handleAddProductToShoppingCart = useCallback(
    async (product: API.WebShoppingCartItemVo) => {
      if (isLogin) {
        if (authorizedUserShoppingCartCacheData) {
          const index = authorizedUserShoppingCartCacheData.findIndex(
            (item: any) => item.productId === product.productId && +item.skuId === +product.skuId, //一定要同时比较productId和skuId
          ); //skuId 判断兼容数字和字符串，productId是长字符串不会是数字（大数）
          if (index !== -1) {
            authorizedUserShoppingCartCacheData[index].quantity += product.quantity;
          } else {
            authorizedUserShoppingCartCacheData.push(product);
          }
          // setAuthorizedUserShoppingCartCacheData(authorizedUserShoppingCartCacheData);
        } else {
          // setAuthorizedUserShoppingCartCacheData([product]);
        }
        const { productId, skuId, quantity } = product;
        postProductWebShoppingCartAdd({ productId, skuId, quantity }).then(async () => {
          const { data } = await getProductWebShoppingCartList();
          if (data) setAuthorizedUserShoppingCartCacheData(data);
        });
      } else {
        if (anonymousUserShoppingCartCacheData) {
          const index = anonymousUserShoppingCartCacheData.findIndex(
            (item: any) => item.productId === product.productId && item.skuId === product.skuId, //一定要同时比较productId和skuId
          );
          if (index !== -1) {
            anonymousUserShoppingCartCacheData[index].quantity += product.quantity;
          } else {
            anonymousUserShoppingCartCacheData.push(product);
          }
          setAnonymousUserShoppingCartCacheData(anonymousUserShoppingCartCacheData);
        } else {
          setAnonymousUserShoppingCartCacheData([product]);
        }
      }
    },
    [
      isLogin,
      authorizedUserShoppingCartCacheData,
      setAuthorizedUserShoppingCartCacheData,
      anonymousUserShoppingCartCacheData,
      setAnonymousUserShoppingCartCacheData,
    ],
  );

  /**
   * 更新商品到购物车
   * @param products 商品信息-数组
   * 说明：如果用户已登录，则直接调用接口添加商品到购物车，否则将商品添加到本地缓存中
   */
  const handleUpdateProductsToShoppingCart = useCallback(
    async (products: API.WebShoppingCartItemVo[]) => {
      if (isLogin) {
        setAuthorizedUserShoppingCartCacheData(products);
      } else {
        setAnonymousUserShoppingCartCacheData(products);
      }
    },
    [isLogin, setAuthorizedUserShoppingCartCacheData, setAnonymousUserShoppingCartCacheData],
  );

  /**
   * 删除购物车中的商品
   */
  const handleDeleteShoppingCartItem = useCallback(
    (params: API.postProductWebShoppingCartRemoveParams) => {
      // console.log(params);
      // console.log(authorizedUserShoppingCartCacheData);
      // return;

      if (isLogin) {
        setAuthorizedUserShoppingCartCacheData(
          (v: any) => v?.filter((item: any) => !params?.ids?.includes(item.id)) ?? [],
        );
      } else {
        setAnonymousUserShoppingCartCacheData(
          (v: any) => v?.filter((item: any) => !params?.ids?.includes(item.id)) ?? [],
        );
      }
    },
    [isLogin, setAnonymousUserShoppingCartCacheData, setAuthorizedUserShoppingCartCacheData],
  );

  /**
   * 清除缓存中的购物车数据
   */
  const handleCleanCacheShoppingCartData = useCallback(() => {
    if (isLogin) {
      setAuthorizedUserShoppingCartCacheData([]);
      putProductWebShoppingCart([]).then(() => {
        console.log('购物车已清空');
      });
    } else {
      setAnonymousUserShoppingCartCacheData([]);
    }
  }, [isLogin, setAnonymousUserShoppingCartCacheData, setAuthorizedUserShoppingCartCacheData]);

  useEffect(() => {
    getAnonymousUser();
  }, []);

  useEffect(() => {
    /**
     * 1、本地必须存在token数据，否则不执行
     * 2、如果token过期了，则将 token key下的缓存数据同步到 匿名用户的缓存中。
     */
    if (isLogin && authorizedUserShoppingCartCacheData && authorizedUserShoppingCartCacheData.length > 0) {
      getUserWebCustomerProfile().catch(() => {
        // 将 token 缓存数据存放到匿名用户的缓存中
        setAnonymousUserShoppingCartCacheData(authorizedUserShoppingCartCacheData);
        setAuthorizedUserShoppingCartCacheData(undefined);
      });
    }
  }, [
    isLogin,
    authorizedUserShoppingCartCacheData,
    setAnonymousUserShoppingCartCacheData,
    setAuthorizedUserShoppingCartCacheData,
  ]);

  // 只要登录了，切换路由或者页面刷新时，同步云端商品到本地
  useEffect(() => {
    if (!isLogin) return;

    const synchronizeIfNeeded = () => {
      const lastSynchTime = storage.get(CART_PAGE_SYNCH_TIME);
      console.log(CART_PAGE_SYNCH_TIME, lastSynchTime);
      const currentTime = Date.now();
      const hoursSinceLastSync = (currentTime - Number(lastSynchTime)) / (1000 * 60 * 60);

      if (hoursSinceLastSync >= 1 || !lastSynchTime) {
        storage.set(CART_PAGE_SYNCH_TIME, currentTime.toString());
        synchronizeShoppingCartCacheData();
      }
    };
    synchronizeIfNeeded();

    if (pathname.includes('/cart')) {
      // 非购物车页面无需同步，也不该同步
      console.log('购物车页面');
      synchronizeIfNeeded(); // 登录状态下，刷新时，没有调用里面的synchronizeShoppingCartCacheData，后面排查原因

      setTimeout(synchronizeShoppingCartCacheData, 800); // 到购物车页面，延迟一下，先让同步云端的操作完成
    } else {
      // 针对其它页面做处理
      const navigationType = performance.navigation.type;
      console.log(navigationType);
      switch (navigationType) {
        case performance.navigation.TYPE_RELOAD: // nextjs页面正常跳转与刷新时一样的（11.08测试结果，怎么跟11.07不一样??）
          console.log('这是页面刷新');
          synchronizeShoppingCartCacheData();
          break;
        case performance.navigation.TYPE_NAVIGATE:
          console.log('这是正常跳转'); // 这是正常跳转 useLocalStorageState 会保留
          break;
      }
    }
  }, [isLogin, pathname, synchronizeShoppingCartCacheData]);
  // 需要优化：做成页面初始化同步一次数据（刷新也重新加载），然后只要购物车不变动，就不从云端拉取，目前每次切换页面都拉取，
  // 切换页面也不拉取，但目前切换页面会
  // 从云端拉取到本地后，会同步到云端一次，这个难免，因为云端数据替换本地数据本质是数据的变化
  // 目前切换页面必须加载一次，否则刷新会丢失购物车数据 （useLocalStorageState 刷新会丢失数据）

  /**
   * 自动同步本地缓存到云端，监控本地购物车是否有变化
   */
  useEffect(() => {
    // 备注：清空购物车时，需要额外调更新购物车的接口。其它其概况authorizedUserShoppingCartCacheData更新（包括刷新、数量和商品增减），都自动同步到云端
    // （之前清空购物车数据切换页面后会恢复，现清空购物车时额外调更新购物车的接口，解决问题）
    if (!isEmpty(authorizedUserShoppingCartCacheData) && isLogin) {
      const data =
        authorizedUserShoppingCartCacheData?.map((item) => ({
          productId: item.productId,
          skuId: item.skuId,
          quantity: item.quantity,
          id: item.id,
        })) ?? [];
      console.log(authorizedUserShoppingCartCacheData, data);
      const res = uniqBy([...data], 'id');
      console.log(res); // 去重 id

      putProductWebShoppingCart(res).then(() => {
        console.log('cart缓存数据同步成功');
      });
    }
  }, [authorizedUserShoppingCartCacheData, isLogin, synchronizeShoppingCartCacheData]);

  const exposed = {
    shoppingCartSynchronizationAfterLogin,
    cacheShoppingCartData: isLogin ? authorizedUserShoppingCartCacheData : anonymousUserShoppingCartCacheData,
    handleAddProductToShoppingCart,
    handleUpdateProductsToShoppingCart,
    // shoppingCartCount:
    //   (isLogin ? authorizedUserShoppingCartCacheData : anonymousUserShoppingCartCacheData)?.reduce(
    //     (total: number, item: any) => total + item.quantity,
    //     0,
    //   ) ?? 0,
    shoppingCartCount:
      (isLogin ? authorizedUserShoppingCartCacheData : anonymousUserShoppingCartCacheData)?.length ?? 0,
    cleanCacheShoppingCartData: handleCleanCacheShoppingCartData,
    deleteShoppingCartItem: handleDeleteShoppingCartItem,
    synchronizeShoppingCartCacheData,
    onlyForLogin,
    isCartPageLoading,
  };
  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

export const useCartProvider = () => useContext(Context);

export default CartProvider;
