import React from 'react';

const TanIcon = ({ width = '18', height = '18' }) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.3327 9.00001C17.3327 13.6024 13.6017 17.3333 8.99935 17.3333C4.39698 17.3333 0.666016 13.6024 0.666016 9.00001C0.666016 4.39763 4.39698 0.666672 8.99935 0.666672C13.6017 0.666672 17.3327 4.39763 17.3327 9.00001ZM8.99935 13.7917C9.34453 13.7917 9.62435 13.5119 9.62435 13.1667V8.16667C9.62435 7.82149 9.34453 7.54167 8.99935 7.54167C8.65417 7.54167 8.37435 7.82149 8.37435 8.16667V13.1667C8.37435 13.5119 8.65417 13.7917 8.99935 13.7917ZM8.99935 4.83334C9.45959 4.83334 9.83268 5.20643 9.83268 5.66667C9.83268 6.12691 9.45959 6.50001 8.99935 6.50001C8.53911 6.50001 8.16602 6.12691 8.16602 5.66667C8.16602 5.20643 8.53911 4.83334 8.99935 4.83334Z"
      fill="#1890FF"
    />
  </svg>
);

export default TanIcon;
