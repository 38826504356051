import { LoaderIcon } from 'lucide-react';
import React from 'react';

const NewLoading = ({ type, className }: { type?: string; className?: string }) => {
  return (
    <div className={`flex items-center justify-center ${className} z-50`}>
      <LoaderIcon className={`${!!type && type === 'special' ? 'text-white' : 'text-[#4DBAC0]'} size-6 animate-spin`} />
    </div>
  );
};

export default NewLoading;
