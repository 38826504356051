// @ts-ignore
/* eslint-disable */
import request from '@/shared/utils/axios';

/** 识别正面 GET /user/web/ocr/header */
export async function getUserWebOcrHeader(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserWebOcrHeaderParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: { surname: string; name: string; nationality: string; birthday: string; idcardNumber: string };
  }>('/user/web/ocr/header', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 识别背面 GET /user/web/ocr/other */
export async function getUserWebOcrOther(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserWebOcrOtherParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: { place: string; nation: string; unit: string; valid: string; cardNo: string };
  }>('/user/web/ocr/other', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
