// 注册 , 原型Product page Registration
import CustomModal from '../../commons/CustomModal/index';
import VerifyCode from '../commons/VerifyCode/ForRegistration/index';
import PasswordSet from './PasswordSet/index';
import React, { useEffect, useState } from 'react';
import PersonalInfoSubmit from '../PersonalInfoSubmit/PersonalInfoForm';
import EM from '@/shared/utils/EM';
import Captcha from '../commons/Captcha';
import TwoFactorAuthByEmail from '../TwoFactorAuthByEmail/Content';
import DoubleVerifySuccess from '../TwoFactorAuthByEmail/DoubleVerifySuccess';

const Registration = function ({
  triggerIcon,
  callback,
  initialOpen, // setInitialOpen,
  thirdUserId,
  thirdPlatformType,
}: {
  triggerIcon?: any;
  callback?: any;
  initialOpen?: any;
  setInitialOpen?: any;
  thirdUserId?: any;
  thirdPlatformType?: string;
}) {
  const [contentState, setContent] = useState('captcha'); // 用于控制弹窗内走到下一个流程的内容展示
  console.log({ initialOpen });
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen || false);
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [repData, setRepData] = useState<any>();
  const [ticket, setTicket] = useState<string>();
  const [phoneInfo, setPhoneInfo] = useState<{ countryCode: string; phoneNumber: string; country: string }>({} as any); // 用途注册页面初始化

  // 打开注册触发按钮
  useEffect(() => {
    const fn = (e: any) => {
      console.log(e);
      setPhoneInfo(e as any);
      setPhoneNumber(e.countryCode + e.phoneNumber);
      setIsOpen(true);
    };
    EM.addListener('display_register_button', fn);
    return () => {
      EM.removeListener('display_register_button', fn);
    };
  }, []);

  return (
    <div>
      <CustomModal
        triggerIcon={triggerIcon || <>open</>}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        callback={() => {
          // 关闭弹层的回调
          console.log({ callback }); // resetLoginPageState要多测试
          callback?.();
          if (contentState === 'double_verify_success') {
            // 关闭双重验证，需要刷新页面
            window.location.reload();
          }
        }}
      >
        {contentState === 'captcha' && <Captcha getResult={setRepData} setStepNext={() => setContent('msg_verify')} />}
        {contentState === 'msg_verify' && (
          // 设置密码提交就会注册成功，就会转为登录状态。怎么继续后面的流程？
          <VerifyCode
            phoneNumber={phoneNumber}
            token={repData?.token}
            repData={repData}
            setContent={() => setContent('set_password')}
            getResult={setTicket}
            goBack={() => setContent('captcha')}
            // thirdUserId={thirdUserId}
            thirdPlatformType={thirdPlatformType}
          />
        )}
        {contentState === 'set_password' && (
          <PasswordSet
            phoneNumber={phoneNumber?.replace(/(?!^\+)\D/g, '')}
            ticket={ticket}
            abbrCountry={phoneInfo.country}
            // setIsOpen={setIsOpen}
            setNext={() => setContent('personal_info_submit')}
            thirdUserId={thirdUserId}
            thirdPlatformType={thirdPlatformType}
          />
        )}
        {contentState === 'personal_info_submit' && (
          <PersonalInfoSubmit setStepNext={() => setContent('double_verify_success')} />
        )}

        {contentState === 'two_factor_authentication' && <TwoFactorAuthByEmail />}
        {contentState === 'double_verify_success' && <DoubleVerifySuccess />}
      </CustomModal>
    </div>
  );
};

export default Registration;
