import Head from 'next/head';
import Script from 'next/script';
import React from 'react';

interface IProps {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  url?: string;
  scripts?: string[];
  preloads?: string[];
}

const CustomHead = ({ title, description, keywords, image, url, scripts, preloads }: IProps) => {
  const titles = (title: string) => (
    <>
      <title key="title">{title}</title>
      <meta key="og:title" property="og:title" content={title} />
      <meta key="twitter:title" name="twitter:title" content={title} />
    </>
  );

  const descriptions = (description: string) => (
    <>
      <meta key="description" name="description" content={description} />
      <meta key="og:description" property="og:description" content={description} />
      <meta key="twitter:description" name="twitter:description" content={description} />
    </>
  );
  const keywordsMeta = (keywords: string) => <meta name="keywords" content={keywords} />;

  const urls = (url: string) => (
    <>
      <meta key="og:url" property="og:url" content={url} />
      <meta key="twitter:domain" property="twitter:domain" content={url} />
      <meta key="twitter:url" property="twitter:url" content={url} />
    </>
  );

  const images = (image: string) => (
    <>
      <meta key="og:image" property="og:image" content={image} />
      <meta key="twitter:image" name="twitter:image" content={image} />
    </>
  );

  const renderScripts = (scripts: string[]) =>
    scripts.map((script: string, i: number) => <Script key={`script-item-${i}`} src={script} />);

  return (
    <Head>
      {title && titles(title)}
      {description && descriptions(description)}
      {keywords && keywordsMeta(keywords)}
      {url && urls(url)}
      {image && images(image)}
      {scripts && renderScripts(scripts)}
      {preloads?.map((preload) => <link rel="preload" key={preload} href={preload} fetchPriority="high" />)}
    </Head>
  );
};

export default CustomHead;
