// @ts-ignore
/* eslint-disable */
import request from '@/shared/utils/axios';

/** 订单数量 order-service
首页顶部[订单]图标红角标数字,统计规则待定,
需要客户登录鉴权 GET /order/web/order/total */
export async function getOrderWebOrderTotal(options?: { [key: string]: any }) {
  return request<{ code: number; msg: string; data: number }>('/order/web/order/total', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 商品搜索建议 根据用户搜索关键词给出搜索建议列表,最多返回10条建议 GET /product/public/product/search/suggest/hotword */
export async function getProductPublicProductSearchSuggestHotword(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicProductSearchSuggestHotwordParams,
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: string }>('/product/public/product/search/suggest/hotword', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** Banner列表 product-service
 GET /product/public/web/banner/list */
export async function getProductPublicWebBannerList(options?: { [key: string]: any }) {
  return request<{ code: number; msg: string; data: API.WebBannerItemVo[] }>('/product/public/web/banner/list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 热门商品列表 product-service
此接口也用于商品结算页面 GET /product/public/web/popular/product/list */
export async function getProductPublicWebPopularProductList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicWebPopularProductListParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: {
      total: string;
      pageNum: number;
      pageSize: number;
      pages: number;
      list: API.WebProductItemVo[];
      size: number;
      startRow: string;
      endRow: string;
      prePage: number;
      nextPage: number;
      isFirstPage: boolean;
      isLastPage: boolean;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      navigatePages: number;
      navigatepageNums: number[];
      navigateFirstPage: number;
      navigateLastPage: number;
    };
  }>('/product/public/web/popular/product/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 商品搜索 product-service GET /product/public/web/product/search */
export async function getProductPublicWebProductSearch(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicWebProductSearchParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: {
      pageResult: {
        total?: number;
        list?: API.WebProductItemVo[];
        pageNum?: number;
        pageSize?: number;
        size?: number;
        startRow?: number;
        endRow?: number;
        pages?: number;
        prePage?: number;
        nextPage?: number;
        isFirstPage?: boolean;
        isLastPage?: boolean;
        hasPreviousPage?: boolean;
        hasNextPage?: boolean;
        navigatePages?: number;
        navigatepageNums?: number[];
        navigateFirstPage?: number;
        navigateLastPage?: number;
      };
      categoryList: { cateId: number; cateName: string; level: number }[];
    };
  }>('/product/public/web/product/search', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 推荐商品列表 product-service
此接口也用于商品结算页面 GET /product/public/web/recommend/product/list */
export async function getProductPublicWebRecommendProductList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicWebRecommendProductListParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: {
      total: number;
      pageNum: number;
      pageSize: number;
      pages: number;
      list: API.WebProductItemVo[];
      size: number;
      startRow: string;
      endRow: string;
      prePage: number;
      nextPage: number;
      isFirstPage: boolean;
      isLastPage: boolean;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      navigatePages: number;
      navigatepageNums: number[];
      navigateFirstPage: number;
      navigateLastPage: number;
    };
  }>('/product/public/web/recommend/product/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 最近浏览商品列表 product-service
此接口也用于商品结算页面
需要用户登录鉴权 GET /product/web/browse/history */
export async function getProductWebBrowseHistory(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductWebBrowseHistoryParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: {
      total: string;
      pageNum: number;
      pageSize: number;
      pages: number;
      list: API.WebProductItemVo[];
      size: number;
      startRow: string;
      endRow: string;
      prePage: number;
      nextPage: number;
      isFirstPage: boolean;
      isLastPage: boolean;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      navigatePages: number;
      navigatepageNums: number[];
      navigateFirstPage: number;
      navigateLastPage: number;
    };
  }>('/product/web/browse/history', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 收藏数量 product-service
需要用户登录鉴权 GET /product/web/product/collect/total */
export async function getProductWebProductCollectTotal(options?: { [key: string]: any }) {
  return request<{ code: number; msg: string; data: number }>('/product/web/product/collect/total', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 首页配置项接口 首页配置项，用来返回首页配置的杂项。 GET /user/public/web/frontpage-setting */
export async function getUserPublicWebFrontpageSetting(options?: { [key: string]: any }) {
  return request<{
    code: number;
    msg: string;
    data: {
      title: string;
      description: string;
      keywords: string;
      gaKey: string;
      qrCode: string;
      googlePlayUrl: string;
      appStoreUrl: string;
    };
  }>('/user/public/web/frontpage-setting', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 新消息数量 GET /user/web/message/new/total */
export async function getUserWebMessageOpenApiNewTotal(options?: { [key: string]: any }) {
  return request<{ code: number; msg: string; data: number }>('/user/web/message/new/total', {
    method: 'GET',
    ...(options || {}),
  });
}
