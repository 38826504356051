import { ReactNode, useEffect, useState } from 'react';
import Script from 'next/script';
import Header from '@/components/commons/Layout/Header';
import Footer from '@/components/commons/Layout/Footer';
import { useDarkModeProvider } from '@/lib/context';

interface LayoutProps {
  structured?: { [key: string]: string };
  children: ReactNode;
  hideFooter?: boolean;
  hideHeader?: boolean;
  h5HaveHeader?: boolean;
  h5NoFooter?: boolean;
  inCategoryH5Page?: boolean;
  showBannerNotification?: boolean;
}

export default function Layout({
  children,
  structured,
  h5HaveHeader = false,
  h5NoFooter = false,
  hideFooter = false,
  inCategoryH5Page = false,
  showBannerNotification = false,
}: LayoutProps) {
  const { darkMode } = useDarkModeProvider();
  const [showBannerNotificationTemp, setShowBannerNotificationTemp] = useState<boolean>(false);

  useEffect(() => {
    setShowBannerNotificationTemp(showBannerNotification);
  }, [showBannerNotification]);

  return (
    <div className={`parsec-layout overflow-y-auto ${darkMode ? 'bg-black' : 'bg-[#f5f6f6]'}`}>
      <Script
        id="application/ld+json"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structured) }}
      />
      {/* PC的header */}
      <div className="max-md:hidden">
        <Header
          showBannerNotification={showBannerNotificationTemp}
          setShowBannerNotification={setShowBannerNotificationTemp}
        />
      </div>
      {/* H5的header */}
      <div className={`md:hidden ${h5HaveHeader ? '' : 'hidden'}`}>
        <Header
          showBannerNotification={showBannerNotificationTemp}
          setShowBannerNotification={setShowBannerNotificationTemp}
          inCategoryH5Page={inCategoryH5Page}
        />
      </div>
      {/* PC的main */}
      <main className={`${showBannerNotificationTemp ? 'pt-[152px]' : 'pt-[116px]'} max-md:hidden`}>{children}</main>
      {/* H5的main */}
      <main
        className={`h-full w-full md:hidden ${h5HaveHeader ? (showBannerNotificationTemp ? 'pt-[88px]' : 'pt-[52px]') : 'pt-0'} ${h5NoFooter ? 'pb-0' : 'pb-[60px]'}`}
      >
        {children}
      </main>

      {/* PC的footer */}
      <div className="max-md:hidden">{hideFooter ? null : <Footer />}</div>
      {/* H5的footer */}
      <div className={`md:hidden`}>{h5NoFooter ? null : <Footer />}</div>
    </div>
  );
}
