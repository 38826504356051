const IconRule = ({ width = '64', height = '64' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="1701"
  >
    <path
      d="M81.82784 87.808a16.896 16.896 0 0 1 16.896-16.896h839.0656a16.896 16.896 0 0 1 16.896 16.896v867.03616a16.896 16.896 0 0 1-16.896 16.896H98.72384a16.896 16.896 0 0 1-16.896-16.896V87.80288zM887.1424 911.08864V146.69824H149.41184v764.39552H887.1424z m-164.66944-450.51904H245.74976a16.896 16.896 0 0 1-16.896-16.896v-39.4496a16.896 16.896 0 0 1 16.896-16.896h476.71808a16.896 16.896 0 0 1 16.896 16.896v39.4496a16.896 16.896 0 0 1-16.896 16.896zM466.7648 645.2224H245.74976a16.896 16.896 0 0 1-16.896-16.896v-38.13888a16.896 16.896 0 0 1 16.896-16.896h221.01504a16.896 16.896 0 0 1 16.896 16.896v38.144a16.896 16.896 0 0 1-16.896 16.896z"
      fill="#4DBAC0"
      p-id="1702"
    ></path>
  </svg>
);

export default IconRule;
