import Image from 'next/image';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useLocale, useTranslations } from 'next-intl';
import { useRequest } from 'ahooks';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import {
  getProductPublicWebProductDetailProductId,
  putProductWebProductCollectProductId,
} from '@/lib/services/shangpinye';
import { deleteProductWebProductCollectCancelProductId } from '@/lib/services/shangpinshoucang';
import { countAddSeparator } from '@/shared/utils/tools';
import NewLoading from '../NewLoading';
import AddShoppingCart from '@/components/product/AddShoppingCart';
import { useAuthProvider, useCollectProvider, useDarkModeProvider } from '@/lib/context';
import { useRouter } from 'next/router';
import Login from '@/components/logreg/Login';
import AdultProductsWarnning from '../AdultProductsWarnning';

const Goods = ({
  width,
  item,
  showPriceBeforeDiscount,
  inFavoritePage = false,
  handleCollection,
  noAnimation,
  noZoom,
}: {
  width?: number;
  item: API.WebProductItemVo & {
    adult?: boolean;
    subjectKZ?: string;
    subjectEN?: string;
    categoryNameEN?: string;
    categoryNameKZ?: string;
    categoryId?: number;
    parentCateId?: number;
  };
  showPriceBeforeDiscount?: boolean;
  inFavoritePage?: boolean;
  handleCollection?: (value: boolean, item: API.WebProductItemVo) => void;
  noAnimation?: boolean;
  noZoom?: boolean;
}) => {
  const { isLogin } = useAuthProvider();
  const currentLocale = useLocale();
  const { handleRefreshColelectTotal } = useCollectProvider();
  const { pathname, push } = useRouter();
  const [openShareBox, setOpenShareBox] = useState<boolean>(false);

  const goodsAddToCartButtonRef = useRef<HTMLImageElement>(null); // 加入购物车按钮
  const favoritedButtonRef = useRef<HTMLImageElement>(null); // 收藏取消收藏按钮
  const t = useTranslations();
  const cardItemRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [openAddCart, setOpenAddCart] = useState<boolean>(false); //下载APP
  const [openLogin, setOpenLogin] = useState<boolean>(false); //登录
  const [showAdultProductsWarnning, setShowAdultProductsWarnning] = useState<boolean>(false); // 展示成人用品提醒弹窗

  const [isLoginNew, setIsLoginNew] = useState<boolean>(false);

  useEffect(() => {
    setIsLoginNew(!!isLogin);
  }, [isLogin]);

  useEffect(() => {
    setIsVisible(!!noAnimation);
  }, [noAnimation]);

  const [favorited, setFavorited] = useState<boolean>(false);

  useEffect(() => {
    setFavorited(item.favorited);
  }, [item.favorited]);

  useEffect(() => {
    const current = cardItemRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, []);

  //  收藏商品
  const { run: collectProduct, loading: collectProductLoading } = useRequest(
    () =>
      putProductWebProductCollectProductId({
        productId: item?.productId + '',
      }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res?.code === 0) {
          if (handleRefreshColelectTotal) {
            handleRefreshColelectTotal();
          }
          if (handleCollection) {
            handleCollection(true, item);
          }
        }
      },
    },
  );
  //  取消收藏商品
  const { run: cancelCollection, loading: cancelCollectionLoading } = useRequest(
    () =>
      deleteProductWebProductCollectCancelProductId({
        productId: item?.productId + '',
      }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res?.code === 0) {
          if (handleRefreshColelectTotal) {
            handleRefreshColelectTotal();
          }
          if (handleCollection) {
            handleCollection(false, item);
          }
        }
      },
    },
  );

  const [loading, setLoading] = useState(false);
  const [productDetail, setProductDetail] = useState<any>();

  // 加入购物车，改为调用详情接口获取信息，不从列表里取
  const run = useCallback(() => {
    setLoading(true);

    // 商品详情
    getProductPublicWebProductDetailProductId({
      productId: item.productId,
    }).then((response) => {
      setProductDetail({
        ...response?.data,
        productId: response?.data?.id,
        category: item.category,
        categoryNameEN: item.categoryNameEN,
        categoryNameKZ: item.categoryNameKZ,
      });
      setTimeout(() => {
        setOpenAddCart(true);
        setLoading(false);
      }, 500);
    });
  }, [item]);

  const { darkMode } = useDarkModeProvider();

  const getReviewsSuffix = (reviewsTotal: number) => {
    switch (reviewsTotal) {
      case 0:
        return t('Goods.zeroStar');
      case 1:
        return t('Goods.oneStar');
      case 2:
        return t('Goods.twoStar');
      case 3:
        return t('Goods.threeStar');
      case 4:
        return t('Goods.fourStar');
      default:
        return t('Goods.fiveStar');
    }
  };

  return (
    <>
      <div
        className={`cursor-pointer ${
          isVisible ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
        } ${noZoom ? '' : 'hover:translate-y-[-2px] hover:scale-105'} relative w-full transform transition-transform duration-500 ${inFavoritePage ? 'box-border flex flex-row items-start justify-between p-3' : ''}`}
        ref={cardItemRef}
        onClick={(e) => {
          // 点击加入购物车按钮不跳转详情页面
          if (goodsAddToCartButtonRef?.current?.contains(e.target as Node)) return;
          // 点击收藏/取消收藏不跳转详情页面
          if (favoritedButtonRef?.current?.contains(e.target as Node)) return;

          if (item?.adult) {
            setShowAdultProductsWarnning(true);
          } else {
            push(
              `/product/${item?.productId}${
                pathname === '/search'
                  ? `?category=${
                      currentLocale === 'en'
                        ? item?.categoryNameEN || item?.category
                        : currentLocale === 'ru'
                          ? item?.category
                          : item?.categoryNameKZ || item?.category
                    }`
                  : ''
              }`,
            );
          }
        }}
      >
        {/* 分期tag 超过或等于2400才显示 */}
        {((item?.price || 0) * Number(item?.sale?.split('%')[0])) / 100 >= 2400 && (
          <div
            className={`absolute left-2 top-3 z-50 ${inFavoritePage ? 'max-md:left-4 max-md:top-4' : ''} flex h-5 w-[70px] flex-row items-center justify-center rounded-full bg-primary text-xs font-normal text-white`}
          >
            0•0•24
          </div>
        )}
        {/* 爱心图标 */}
        <Image
          src={favorited ? '/images/goods/love.png' : '/images/goods/collect.png'}
          width={24}
          ref={favoritedButtonRef}
          height={24}
          className={`absolute right-3 top-3 z-50 size-6 cursor-pointer max-md:top-2 max-md:mr-2 max-md:size-5`}
          alt="collectImage"
          onClick={() => {
            if (!isLoginNew) {
              setOpenLogin(true);
              return;
            }
            // toast({
            //   description: (
            //     <div className="flex flex-row items-center justify-start font-semibold">
            //       {favorited ? (
            //         ''
            //       ) : (
            //         <Image
            //           src={'/images/product/messages.png'}
            //           width={24}
            //           height={24}
            //           className="mr-2 size-6"
            //           alt="productImages"
            //         />
            //       )}
            //       {favorited
            //         ? t('Product.thisProductHasBeenRemovedFromTheFavorites')
            //         : t('Product.thisProductHasBeenAddedToTheFavorites')}
            //     </div>
            //   ),
            // });

            setFavorited(!favorited);

            if (collectProductLoading || cancelCollectionLoading) {
              return;
            }
            if (favorited) {
              //   取消收藏
              cancelCollection();
            } else {
              collectProduct();
            }
          }}
        />
        <div>
          {item?.image ? (
            <Image
              src={item?.image}
              width={width || 180}
              height={width || 180}
              className={`box-border overflow-hidden object-cover ${inFavoritePage ? 'mb-0 mr-3 size-28 rounded-2xl max-md:h-[112px] max-md:w-[112px]' : `mb-2 ${width ? `h-[${width}px] w-[${width}px]` : 'h-[180px] w-[180px] max-md:h-[45.87vw] max-md:w-[45.87vw]'} rounded-xl`}`}
              alt="goodsImage"
            />
          ) : (
            <div
              className={`bg-[#f6f6f6] ${inFavoritePage ? 'mb-0 mr-3 size-28 rounded-2xl max-md:h-[112px] max-md:w-[112px]' : `mb-2 rounded-xl ${width ? `h-[${width}px] w-[${width}px]` : 'h-[180px] w-[180px] max-md:h-[45.87vw] max-md:w-[45.87vw]'} rounded-xl`}`}
            ></div>
          )}
        </div>
        <div className={`${inFavoritePage ? 'flex flex-1 flex-col items-start justify-between' : 'w-full'}`}>
          <div className={`w-full ${inFavoritePage ? 'hidden' : ''}`}>
            {item?.subject ? (
              <div className="w-full">
                <div className="w-full md:hidden">
                  <h3
                    className={`truncate text-left text-sm font-medium ${darkMode ? 'text-white' : 'text-black'} w-full first-letter:uppercase`}
                  >
                    {currentLocale === 'en'
                      ? item?.subjectEN || item?.subject
                      : currentLocale === 'ru'
                        ? item?.subject
                        : item?.subjectKZ || item?.subject}
                  </h3>
                </div>
                <div className="w-full max-md:hidden">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <h3
                          className={`truncate text-left text-sm font-medium ${darkMode ? 'text-white' : 'text-black'} first-letter:uppercase ${width ? `w-[${width}px]` : 'w-[180px] max-md:w-[45.87vw]'}`}
                        >
                          {currentLocale === 'en'
                            ? item?.subjectEN || item?.subject
                            : currentLocale === 'ru'
                              ? item?.subject
                              : item?.subjectKZ || item?.subject}
                        </h3>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p
                          className={`first-letter:uppercase ${width ? `max-w-[${width}px]` : 'max-w-[180px] max-md:max-w-[45.87vw]'}`}
                        >
                          {currentLocale === 'en'
                            ? item?.subjectEN || item?.subject
                            : currentLocale === 'ru'
                              ? item?.subject
                              : item?.subjectKZ || item?.subject}
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
            ) : (
              ''
            )}
            {item?.category ? (
              <h3
                className={`mb-1 ${item?.subject ? 'mt-[-6px] max-md:mt-0' : ''} truncate text-left text-xs font-medium ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'} max-md:mb-2 ${width ? `w-[${width}px]` : 'w-[180px] max-md:w-[45.87vw]'}`}
              >
                {currentLocale === 'en'
                  ? item?.categoryNameEN || item?.category
                  : currentLocale === 'ru'
                    ? item?.category
                    : item?.categoryNameKZ || item?.category}
              </h3>
            ) : (
              ''
            )}
          </div>
          <div className={`w-[86%] ${inFavoritePage ? 'mb-3' : 'hidden'}`}>
            <h3 className={`text-sm font-medium ${darkMode ? 'text-white' : 'text-black'}`}>{item?.subject}</h3>
            <h3 className={`text-sm font-medium ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}>
              {' '}
              {currentLocale === 'en'
                ? item?.categoryNameEN || item?.category
                : currentLocale === 'ru'
                  ? item?.category
                  : item?.categoryNameKZ || item?.category}
            </h3>
          </div>
          <div
            className={`${inFavoritePage ? 'hidden' : ''} mb-1 flex w-full flex-row items-center justify-start max-md:mb-2`}
          >
            <Image
              src={'/images/goods/like.png'}
              className="mr-1 size-6 max-md:size-5"
              alt="likeImage"
              width={24}
              height={24}
            />
            <div className={`text-xs ${darkMode ? 'text-white' : 'text-black'}`}>
              {item?.score || 0} ({countAddSeparator(item?.reviews || 0)} {getReviewsSuffix(item?.reviews || 0)})
            </div>
          </div>
          {/* 加入购物车图标 */}
          <div className="flex w-full flex-row items-center justify-between">
            <div
              className={`${inFavoritePage ? 'text-base' : 'text-xs'} font-medium ${darkMode ? 'text-white' : 'text-black'}`}
            >
              {countAddSeparator(((item?.price || 0) * Number(item?.sale?.split('%')[0])) / 100)} ₸{' '}
              {showPriceBeforeDiscount ? (
                <span className={`ml-[6px] ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'} line-through`}>
                  {item?.sale === '100%' ? '' : item?.sale ? countAddSeparator(item?.price || 0) + ' ₸' : ''}
                </span>
              ) : (
                ''
              )}
            </div>
            <div
              ref={goodsAddToCartButtonRef}
              onClick={() => run()}
              className={`flex cursor-pointer flex-row items-center justify-center rounded-lg bg-primary ${inFavoritePage ? 'flex h-8 w-[110px] flex-row items-center justify-center' : 'h-8 w-12 max-md:h-7'}`}
            >
              {loading ? (
                <NewLoading type="special" className="size-4" />
              ) : (
                <>
                  {inFavoritePage ? (
                    <div className={`text-xs font-medium ${darkMode ? 'text-white' : 'text-black'}`}>
                      {t('Orders.addToCart')}
                    </div>
                  ) : (
                    <Image
                      src={'/images/goods/shoppingCart.png'}
                      className="size-4"
                      width={16}
                      height={16}
                      alt="shoppingCart"
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {/* 24期，每期价格向上取整，展示 */}
          {((item?.price || 0) * Number(item?.sale?.split('%')[0])) / 100 >= 2400 && (
            <div className="mt-2 flex w-full flex-row items-center justify-start">
              <div
                className={`mr-1 h-5 min-w-16 rounded-md ${darkMode ? 'bg-[#3A969B1F]' : 'bg-[#4DBAC03D]'} flex flex-row items-center justify-center px-2 text-xs font-normal ${darkMode ? 'text-white' : 'text-black'}`}
              >
                {countAddSeparator(Math.ceil(((item?.price || 0) * Number(item?.sale?.split('%')[0])) / 100 / 24) || 0)}{' '}
                ₸
              </div>
              <div className={`${darkMode ? 'text-white' : 'text-black'} text-xs font-medium`}>X 24</div>
            </div>
          )}
        </div>
      </div>
      {/* 加入购物车 弹窗 */}
      <Dialog open={openAddCart} onOpenChange={(value) => setOpenAddCart(value)}>
        <DialogTitle></DialogTitle>
        <DialogContent className="p-0 max-md:bottom-0 max-md:top-auto max-md:translate-y-0">
          <div className="max-md:max-w-screen flex w-[1240px] flex-row items-center justify-center rounded-3xl bg-white p-8 max-md:bottom-0 max-md:h-[86vh] max-md:w-screen max-md:translate-y-0 max-md:overflow-y-auto max-md:rounded-b-none max-md:p-3">
            {loading ? (
              <NewLoading />
            ) : productDetail ? (
              <AddShoppingCart
                buttonWidth={222}
                showMoreButton={true}
                onClickMoreButton={() => setOpenAddCart(false)}
                productDetail={{
                  ...productDetail,
                  subject: productDetail.subject,
                  id: productDetail.productId,
                  favorited: favorited,
                  price: productDetail.price,
                  skuList: (productDetail.skuList || []).map((x: any) => {
                    return {
                      ...x,
                      skuAttributes: (x?.skuAttributes || []).map((skuAttributeItem: any) => {
                        return {
                          ...skuAttributeItem,
                          attributeId: skuAttributeItem?.attributeId + '',
                        };
                      }),
                    };
                  }),
                  productImages: productDetail.productImages,
                  productBuySpecification: productDetail.productBuySpecification || [],
                  score: productDetail.score,
                  reviewsTotal: productDetail.reviews,
                  sales: productDetail?.sales,
                  categoryName: productDetail.category,
                  categoryNameEN: productDetail.categoryNameEN,
                  categoryNameKZ: productDetail.categoryNameKZ,
                }}
                onOpenShareBox={(value) => setOpenShareBox(value)}
                closeDialog={() => setOpenAddCart(false)}
                onFavoritedChange={(value) => setFavorited(value)}
              />
            ) : (
              ''
            )}
            {openShareBox ? (
              <div className="absolute left-0 top-0 h-full w-full rounded-3xl bg-[rgba(0,0,0,0.24)]"></div>
            ) : (
              ''
            )}
          </div>
        </DialogContent>
      </Dialog>
      {/* 登录 弹窗 */}
      <Dialog open={openLogin} onOpenChange={(value) => setOpenLogin(value)}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <div className="flex w-[274px] flex-col items-center justify-center rounded-3xl bg-white p-6">
            <Login isLoginOpenOuter={true} triggerIcon={<></>} setLoginOpenOuter={() => setOpenLogin(false)} />
          </div>
        </DialogContent>
      </Dialog>
      {/* 成人用品分类访问确认弹窗 */}
      <Dialog open={showAdultProductsWarnning} onOpenChange={(value) => setShowAdultProductsWarnning(value)}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <AdultProductsWarnning
            darkMode={darkMode}
            onOk={() => {
              setShowAdultProductsWarnning(false);
              push(
                `/product/${item?.productId}${
                  pathname === '/search'
                    ? `?category=${
                        currentLocale === 'en'
                          ? item?.categoryNameEN || item?.category
                          : currentLocale === 'ru'
                            ? item?.category
                            : item?.categoryNameKZ || item?.category
                      }`
                    : ''
                }`,
              );
            }}
            onCancel={() => setShowAdultProductsWarnning(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Goods;
