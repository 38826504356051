/* eslint-disable */
import { TouchEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import defaultImg from '../../Captcha/assets/images/default.jpg';
import styles from '../../Captcha/assets/index.module.css';
import { useTranslations } from 'next-intl';
import backImgBase64 from '../../Captcha/components/backImgBase64';
import blockBackImgBase64 from '../../Captcha/components/blockBackImgBase64';
import { getPicture, reqCheck } from '../../Captcha/api/base';
import { aesEncrypt } from '../../Captcha/api/ase';

function stringToBase64(str: string) {
  console.log(str);
  var encodedStr = encodeURIComponent(str);
  var base64Str = btoa(encodedStr);
  return base64Str;
}

// function stringToBase64(str:string) {
//   const encoder = new TextEncoder();
//   const bytes = encoder.encode(str);
//   const base64String = btoa(String.fromCharCode.apply(null, bytes));
//   return base64String;
// }

// 47:310

// 200  310

const IMG_HEIGHT = 170;
const IMG_WIDTH = 340;
const PADDING = 15;

const VerifySlideFixed = (props: any) => {
  const t = useTranslations('LoregCommon');
  const { setStepNext, getResult, noCheck } = props;
  const [state, setState] = useState({
    backImgBase: backImgBase64 || '', // 验证码背景图片
    blockBackImgBase: blockBackImgBase64 || '', // 验证滑块的背景图片
    backToken: '', // 后端返回的唯一token值
    startMoveTime: 0, //移动开始的时间
    endMovetime: '', //移动结束的时间
    tipsBackColor: '', //提示词的背景颜色
    secretKey: '', //后端返回的加密秘钥 字段
    captchaType: 'blockPuzzle',
    moveBlockBackgroundColor: 'rgb(255, 255, 255)',
    leftBarBorderColor: '',
    iconColor: '',
    barAreaLeft: 0,
    barAreaOffsetWidth: 0,
    startLeft: 0,
    moveBlockLeft: '',
    leftBarWidth: '',
    status: false, //鼠标状态
    isEnd: false, //是够验证完成
    passFlag: '',
    tipWords: '',
    text: t('swipeRight'), // '向右滑动完成验证',
  });

  const setSize = {
    imgHeight: IMG_HEIGHT,
    imgWidth: IMG_WIDTH,
  };

  const blockSize = {
    width: '50px',
    height: '50px',
  };

  const barAreaRef = useRef<any>(null);

  const [repDataGet, setRepDataGet] = useState<any>();

  const getData = useCallback(() => {
    getPicture({
      captchaType: state.captchaType,
      clientUid: localStorage.getItem('slider'),
      ts: Date.now(),
    }).then((res) => {
      if (res.repCode === '0000') {
        setState((prevState) => ({
          ...prevState,
          backImgBase: res.repData.originalImageBase64,
          blockBackImgBase: res.repData.jigsawImageBase64,
          backToken: res.repData.token,
          secretKey: res.repData.secretKey,
        }));

        // if (noCheck) {
        //   setRepDataGet(res.repData);
        // }
        setRepDataGet(res.repData);
      }
      if (res.repCode === '6201') {
        setState(
          (prevState) =>
            ({
              ...prevState,
              backImgBase: null,
              blockBackImgBase: null,
              leftBarBorderColor: '#d9534f',
              iconColor: '#fff',
              iconClass: 'icon-close',
              passFlag: false,
              // tipWords: res.repData.secretKey,
            }) as any,
        );
        setTimeout(() => {
          setState((prevState) => ({ ...prevState, tipWords: '' }));
        }, 1000);
      }
    });
    // state.captchaType
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.captchaType]);

  const refresh = useCallback(() => {
    getData();

    setState(
      (prevState) =>
        ({
          ...prevState,
          moveBlockLeft: '',
          leftBarWidth: '',
          text: t('swipeRight'), //'向右滑动完成验证',
          moveBlockBackgroundColor: '#fff',
          leftBarBorderColor: '#337AB7',
          iconColor: '#fff',
          status: false,
          isEnd: false,
        }) as any,
    );
  }, [getData]);

  const start: TouchEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault(); // 阻止默认行为
      e.stopPropagation(); // 阻止事件冒泡

      // e = e || window.event;
      const x = e.touches[0].pageX;
      // const x = e.clientX;
      console.log(x);
      // alert('start')

      setState(
        (prevState) =>
          ({
            ...prevState,
            startLeft: Math.floor(x - prevState.barAreaLeft),
            startMoveTime: +new Date(),
          }) as any,
      );

      if (state.isEnd === false) {
        console.log('start-02', state);

        // alert('start-02')
        setState((prevState) => ({
          ...prevState,
          text: '',
          moveBlockBackgroundColor: '#337ab7',
          leftBarBorderColor: '#337AB7',
          iconColor: '#fff',
          status: true,
        }));
        e.stopPropagation();
      }
    },
    [state],
  );

  const move: TouchEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      // e = e || window.event;

      if (state.status && state.isEnd === false) {
        // console.log(state,state.barAreaOffsetWidth,blockSize.width);
        const x = e.touches[0].pageX;
        // const x = e.clientX;
        const moveBlockLeft = x - state.barAreaLeft;
        // console.log(moveBlockLeft);

        if (moveBlockLeft >= state.barAreaOffsetWidth - parseInt(blockSize.width) / 2 - 2) {
          console.log(state.barAreaOffsetWidth);

          setState(
            (prevState) =>
              ({
                ...prevState,
                moveBlockLeft: state.barAreaOffsetWidth - parseInt(blockSize.width) / 2 + 25 + 'px',
                leftBarWidth: state.barAreaOffsetWidth - parseInt(blockSize.width) / 2 - 2 + 'px',
              }) as any,
          );
        } else if (moveBlockLeft <= 0) {
          console.log(2);
          // return
          setState(
            (prevState) =>
              ({
                ...prevState,
                moveBlockLeft: '0',
                leftBarWidth: '0',
              }) as any,
          );
        } else {
          console.log(3);
          // return
          setState(
            (prevState) =>
              ({
                ...prevState,
                moveBlockLeft: moveBlockLeft - state.startLeft + 'px',
                leftBarWidth: moveBlockLeft - state.startLeft + 'px',
              }) as any,
          );
        }
      }
    },
    [blockSize.width, state],
  );

  const end = useCallback(() => {
    const endMovetime = +new Date();
    if (state.status && state.isEnd === false) {
      const moveLeftDistance = parseInt((state.moveBlockLeft || '').replace('px', ''));
      console.log(moveLeftDistance);

      const data = {
        captchaType: state.captchaType,
        pointJson: state.secretKey
          ? aesEncrypt(JSON.stringify({ x: (moveLeftDistance * 310) / IMG_WIDTH, y: 5.0 }), state.secretKey)
          : JSON.stringify({ x: moveLeftDistance, y: 5.0 }),
        token: state.backToken,
        clientUid: localStorage.getItem('slider'),
        ts: Date.now(),
      };

      // if(noCheck){
      //   getResult(repData)
      //   setStepNext();
      //   return
      // }

      reqCheck(data).then((res) => {
        if (res.repCode === '0000') {
          setState(
            (prevState) =>
              ({
                ...prevState,
                isEnd: true,
                passFlag: true,
                tipWords: `${((endMovetime - prevState.startMoveTime) / 1000).toFixed(2)}s验证成功`,
              }) as any,
          );
          // setTimeout(() => {
          //   setState((prevState) => ({ ...prevState, tipWords: '' }));
          //   refresh();
          // }, 1000);
          setStepNext();
          console.log(res.repData);
          if (getResult) {
            getResult({
              ...res.repData,
              clientUid: localStorage.getItem('slider'),
              captchaVerification: stringToBase64(
                aesEncrypt(res.repData.token + '---' + res.repData.pointJson, repDataGet.secretKey),
              ),
              secretKey: repDataGet.secretKey,
              ticket: res.ticket,
            });
          }
        } else {
          setState(
            (prevState) =>
              ({
                ...prevState,
                isEnd: true,
                moveBlockBackgroundColor: '#d9534f',
                leftBarBorderColor: '#d9534f',
                iconColor: '#fff',
                iconClass: 'icon-close',
                passFlag: false,
                // tipWords: res.repData.result || '验证失败',
              }) as any,
          );
          setTimeout(() => {
            refresh();
            setState((prevState) => ({ ...prevState, tipWords: '' }));
          }, 1000);
        }
      });

      setState((prevState) => ({ ...prevState, status: false }));
    }
  }, [refresh, state.backToken, state.captchaType, state.isEnd, state.moveBlockLeft, state.secretKey, state.status]);

  useEffect(() => {
    const handleMove: any = move;
    // handleMove类型如何写？

    const handleEnd = end;

    window.addEventListener('touchmove', handleMove);
    window.addEventListener('mousemove', handleMove);
    window.addEventListener('touchend', handleEnd);
    window.addEventListener('mouseup', end);

    return () => {
      window.removeEventListener('touchmove', handleMove);
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('touchend', handleEnd);
      window.removeEventListener('mouseup', end);
    };
  }, [end, move, state]);

  const uuid = () => {
    const s = [];
    const hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4';
    s[19] = hexDigits.substr((s[19] as any & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = '-';

    const slider = 'slider' + '-' + s.join('');
    const point = 'point' + '-' + s.join('');

    if (!localStorage.getItem('slider')) {
      localStorage.setItem('slider', slider);
    }
    if (!localStorage.getItem('point')) {
      localStorage.setItem('point', point);
    }
  };

  const setBarArea = useCallback(() => {
    if (barAreaRef.current) {
      const barAreaLeft = barAreaRef.current.getBoundingClientRect().left;
      const barAreaOffsetWidth = barAreaRef.current.offsetWidth;
      console.log({ barAreaOffsetWidth });

      setState((prevState) => ({
        ...prevState,
        barAreaLeft,
        barAreaOffsetWidth,
      }));
    }
  }, []);

  useEffect(() => {
    uuid();
    getData();
    setBarArea();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeBox = () => {
    props.verifyPointFixedChild(false);
  };

  return (
    <div className={styles.mask} style={{ display: props.isSlideShow ? 'block' : 'none' }}>
      <div className={styles.verifybox} style={{ maxWidth: parseInt(props.imgSize.width) + PADDING * 2 + 'px' }}>
        <div className={styles.verifyboxTop}>
          <div>{t('saftyVerify')}</div>
          <span className={styles.verifyboxClose} onClick={closeBox}>
            {/* <i className={styles.iconfont.iconClose}></i> */}
            <i className="iconClose"></i>
          </span>
        </div>
        <div className="verifyboxBottom" style={{ padding: `${PADDING}px` }}>
          <div style={{ position: 'relative' }} className="stop-user-select">
            <div className="verify-img-out" style={{ height: setSize.imgHeight + props.vSpace }}>
              <div className="verify-img-panel" style={{ width: setSize.imgWidth, height: setSize.imgHeight }}>
                {state.backImgBase ? (
                  <img
                    src={'data:image/png;base64,' + state.backImgBase}
                    alt=""
                    style={{ width: '100%', height: '100%', display: 'block' }}
                  />
                ) : (
                  <img src={defaultImg as any} alt="" style={{ width: '100%', height: '100%', display: 'block' }} />
                )}
                <div className="verify-refresh" onClick={refresh}>
                  <i className="iconfont icon-refresh"></i>
                </div>
                <CSSTransition in={state.tipWords.length > 0} timeout={150} classNames="tips" unmountOnExit>
                  <span className={state.passFlag ? 'verify-tips suc-bg' : 'verify-tips err-bg'}>{state.tipWords}</span>
                </CSSTransition>
              </div>
            </div>

            <div
              className="verify-bar-area"
              style={{
                width: setSize.imgWidth,
                height: props.barSize.height,
                lineHeight: props.barSize.height,
              }}
              ref={barAreaRef}
              onClick={setBarArea}
            >
              <span className="verify-msg">{state.text}</span>
              <div
                className="verify-left-bar"
                style={{
                  width: state.leftBarWidth !== undefined ? state.leftBarWidth : props.barSize.height,
                  height: props.barSize.height,
                  borderColor: state.leftBarBorderColor,
                  // transaction: props.transitionWidth,
                }}
              >
                <span className="verify-msg">{props.finishText}</span>

                <div
                  className="verify-move-block"
                  onTouchStart={start}
                  // onMouseDown={start}
                  style={{
                    width: props.barSize.height,
                    height: props.barSize.height,
                    backgroundColor: state.moveBlockBackgroundColor,
                    left: state.moveBlockLeft,
                    transition: props.transitionLeft,
                  }}
                >
                  <i className="verify-icon iconfont icon-right" style={{ color: state.iconColor }}></i>
                  <div
                    className="verify-sub-block"
                    style={{
                      width: Math.floor((setSize.imgWidth * 47) / 310) + 'px',
                      height: setSize.imgHeight,
                      top: '-' + (setSize.imgHeight + props.vSpace) + 'px',
                      backgroundSize: setSize.imgWidth + ' ' + setSize.imgHeight,
                    }}
                  >
                    <img
                      src={'data:image/png;base64,' + state.blockBackImgBase}
                      alt=""
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'block',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VerifySlideFixed.defaultProps = {
  mode: 'fixed',
  vSpace: 5,
  imgSize: {
    width: IMG_WIDTH + 'px',
    height: IMG_HEIGHT + 'px',
  },
  barSize: {
    width: IMG_WIDTH + 'px',
    height: '40px',
  },
  setSize: {
    imgHeight: IMG_HEIGHT,
    imgWidth: IMG_WIDTH,
  },
};

export default VerifySlideFixed;
