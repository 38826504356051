import { useTranslations } from 'next-intl';
import Image from 'next/image';

const AdultProductsWarnning = ({
  darkMode,
  onOk,
  onCancel,
}: {
  darkMode?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}) => {
  const t = useTranslations();

  return (
    <div
      className={`min-h-[404px] w-[428px] rounded-3xl p-6 max-md:w-screen ${darkMode ? 'bg-[#141414]' : 'bg-white'} box-border flex flex-col items-center justify-between`}
    >
      <div className="mb-6 box-border flex w-full flex-col items-center justify-between py-3">
        <Image src={'/images/category/users.png'} width={96} height={96} className="size-24" alt="User" />
        <div className={`mb-2 mt-3 text-base font-semibold ${darkMode ? 'text-white' : 'text-black'}`}>
          {t('Categories.confirmYourAge')}
        </div>
        <div className={`text-sm font-normal ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'} text-center`}>
          {t('Categories.warnningContent')}
        </div>
      </div>
      <div className="w-full">
        <div
          className={`mb-3 flex h-12 w-full cursor-pointer flex-row items-center justify-center rounded-xl text-base font-medium ${darkMode ? 'text-white' : 'text-black'} bg-primary hover:bg-[#4dbac0f2]`}
          onClick={onOk}
        >
          {t('Categories.confirm')}
        </div>
        <div
          className={`${darkMode ? 'bg-[#3A969B1F]' : 'bg-[#4DBAC03D]'} flex h-12 w-full cursor-pointer flex-row items-center justify-center rounded-xl text-base font-medium ${darkMode ? 'text-[#3A969B]' : 'text-primary'}`}
          onClick={onCancel}
        >
          {t('Categories.cancel')}
        </div>
      </div>
    </div>
  );
};

export default AdultProductsWarnning;
