/* eslint-disable @typescript-eslint/no-unsafe-function-type */
// messageUtil.ts
class MessageUtil {
  private handlers: Map<string, Set<Function>> = new Map();

  private static instance: MessageUtil | null = null;

  private constructor() {}

  static getInstance(): MessageUtil {
    if (!MessageUtil.instance) {
      MessageUtil.instance = new MessageUtil();

      // 确保在客户端环境
      if (typeof window !== 'undefined') {
        window.addEventListener('message', MessageUtil.instance.handleMessage.bind(MessageUtil.instance));
      }
    }
    return MessageUtil.instance;
  }

  on(type: string, callback: Function) {
    if (!this.handlers.has(type)) {
      this.handlers.set(type, new Set());
    }
    this.handlers.get(type)?.add(callback);
  }

  off(type: string, callback: Function) {
    this.handlers.get(type)?.delete(callback);
  }

  sendToParent(type: string, data: any) {
    if (typeof window !== 'undefined') {
      window.parent.postMessage({ type, data }, '*');
    }
  }

  // sendToIframe(iframeId: string, type: string, data: any) {
  //   if (typeof window !== 'undefined') {
  //     const iframe = document.getElementById(iframeId);
  //     if (iframe) {
  //       iframe.contentWindow?.postMessage({ type, data }, '*');
  //     }
  //   }
  // }

  private handleMessage(event: MessageEvent) {
    const { type, data } = event.data || {};
    if (type && this.handlers.has(type)) {
      this.handlers.get(type)?.forEach((callback) => callback(data));
    }
  }
}

// 只导出工具实例
export const messageUtil = MessageUtil.getInstance();
