import { useMemo, useState } from 'react';
import { useTranslations } from 'next-intl';
import InputField from '@/components/commons/InputField';
import SecurityIcon from './icons/Security';
import { useRequest } from 'ahooks';
import { getUserWebCustomerProfile } from '@/lib/services/gerenzhongxin';
import { postUserWebEmailDoubleVerifyUrl } from '@/lib/services/gonggongjiekou';
import { message } from 'antd';
import { NewLoading } from '@/components/commons';

const TwoFactorContent = function ({ email }: any) {
  const t = useTranslations('TwoFactorAuth');

  const [emailAddress, setEmail] = useState(email || ''); //邮箱
  const [urlSent, setUrlSentState] = useState(false);

  useRequest(getUserWebCustomerProfile, {
    manual: !!email,
    onSuccess(res) {
      if (res.code === 0) {
        setEmail(res.data.email);
      }
    },
  });

  const { loading, run } = useRequest(postUserWebEmailDoubleVerifyUrl, {
    manual: true,
    onSuccess(res) {
      if (res.code === 0) {
        setUrlSentState(true);
      } else {
        message.error(res.msg);
      }
    },
  });

  const handlePhoneChange = (e: any) => {
    // const value = e.target.value.replace(/\D/g, '')
    const value = e.target.value;

    setEmail(value);
  };

  const disabled = useMemo(() => {
    return !emailAddress || loading;
  }, [emailAddress, loading]);

  return (
    <div className="inset-0 flex min-h-fit min-w-fit items-center justify-center bg-opacity-50">
      <div className="w-[450px] rounded-3xl bg-white p-6 shadow-lg">
        <h2 className="mb-4 text-center text-lg font-semibold">{t('title')}</h2>
        <div className="mb-4 mt-6 flex w-full justify-center">
          <SecurityIcon />
        </div>
        {urlSent ? (
          <p className="mb-2 text-center text-[16px] font-semibold">{t('desc2')}</p>
        ) : (
          <p className="mb-2 text-center text-[16px] font-semibold">{t('desc')}</p>
        )}
        {urlSent ? (
          <>
            <p className="mb-0 text-center text-[14px] text-[#80878E]">{t('desc31')}</p>
            <p className="mb-6 text-center text-[14px] text-[#80878E]">{t('desc32')}</p>
          </>
        ) : (
          <>
            <p className="mb-0 text-center text-[14px] text-[#80878E]">{t('desc21')}</p>
            <p className="mb-6 text-center text-[14px] text-[#80878E]">{t('desc22')}</p>
          </>
        )}

        {!urlSent && (
          <div className="w-full space-y-4">
            <InputField
              label={t('labelInput')}
              placeholder="example@email.com"
              value={emailAddress}
              disabled
              onChange={handlePhoneChange}
              withClearIcon={false}
              labelColor="#000"
            />

            <button
              disabled={disabled}
              className={`w-full rounded-xl py-3 transition duration-150 ease-in-out hover:opacity-90 ${disabled ? 'cursor-not-allowed bg-[var(--primary-clor-gray3)] !text-[var(--theme-gray-1)]' : 'bg-[#4DBAC0]'} text-black`}
              onClick={run}
            >
              {loading ? <NewLoading type="special" /> : t('confirm')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TwoFactorContent;
