const Icon1 = ({ width = '29', height = '30' }) => (
  <svg width={width} height={height} viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.16602 0.666656C5.7183 0.666656 6.16602 1.11437 6.16602 1.66666V13.6667C6.16602 16.2091 6.16814 18.0153 6.35236 19.3855C6.53271 20.727 6.87094 21.4998 7.43522 22.0641C7.9995 22.6284 8.77236 22.9666 10.1138 23.147C11.484 23.3312 13.2903 23.3333 15.8327 23.3333H27.8327C28.385 23.3333 28.8327 23.781 28.8327 24.3333C28.8327 24.8856 28.385 25.3333 27.8327 25.3333H24.8327V28.3333C24.8327 28.8856 24.385 29.3333 23.8327 29.3333C23.2804 29.3333 22.8327 28.8856 22.8327 28.3333V25.3333H15.7575C13.3071 25.3333 11.3663 25.3334 9.84732 25.1291C8.28409 24.919 7.01882 24.4762 6.02101 23.4783C5.02319 22.4805 4.58037 21.2153 4.37019 19.652C4.16598 18.1331 4.16599 16.1922 4.16602 13.7419L4.16602 6.66666H1.16602C0.613731 6.66666 0.166016 6.21894 0.166016 5.66666C0.166016 5.11437 0.613731 4.66666 1.16602 4.66666H4.16602V1.66666C4.16602 1.11437 4.61373 0.666656 5.16602 0.666656ZM18.8849 6.853C17.5147 6.66878 15.7084 6.66666 13.166 6.66666H9.16602C8.61373 6.66666 8.16602 6.21894 8.16602 5.66666C8.16602 5.11437 8.61373 4.66666 9.16602 4.66666L13.2412 4.66666C15.6916 4.66663 17.6324 4.66662 19.1514 4.87084C20.7146 5.08101 21.9799 5.52383 22.9777 6.52165C23.9755 7.51946 24.4183 8.78473 24.6285 10.348C24.8327 11.8669 24.8327 13.8078 24.8327 16.2581V20.3333C24.8327 20.8856 24.385 21.3333 23.8327 21.3333C23.2804 21.3333 22.8327 20.8856 22.8327 20.3333V16.3333C22.8327 13.7909 22.8306 11.9847 22.6463 10.6145C22.466 9.273 22.1278 8.50014 21.5635 7.93586C20.9992 7.37158 20.2263 7.03336 18.8849 6.853Z"
      fill="#4DBAC0"
    />
  </svg>
);

export default Icon1;
