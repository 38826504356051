import React, { useEffect, useRef, useState } from 'react';
import Flag from '../../../commons/Flag';
import SelectIcon from '../../../icons/SelectIcon';
import { CountryCodeType } from '../../../commons/CountryCodes';
import useCountryCodes from '../../../commons/CountryCodes';
import MaskInput from '../MaskInput/ImprovedMaskInput';
import { formatPhoneNumber } from '@/components/logreg/utils';

interface PhoneInputFieldProps {
  label: string;
  value: string;
  selectedCountry: CountryCodeType;
  isValid: boolean;
  onChange: (e: any) => void;
  onCountryChange: (country: CountryCodeType) => void;
  placeholder?: string;
  countryCodes?: CountryCodeType[];
  onFocus?: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const PhoneInputField: React.FC<PhoneInputFieldProps> = ({
  label,
  value,
  selectedCountry,
  isValid,
  onChange,
  onCountryChange,
  onFocus,
  onBlur,
  // placeholder = '771 000 00 00',
  // countryCodes,
  // isOpen,
  // setIsOpen,
}) => {
  const { CountryCodes } = useCountryCodes();
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [placeholder, setPlaceholder] = useState('771 000 00 00');

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleFocus = () => {
    setIsFocused(true);
    onFocus?.();
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  const handlePhoneChange = (e: any) => {
    onChange(e);

    const value = e.target.value;
    const number = formatPhoneNumber(value, 10);
    console.log(number);
    setPlaceholder(() => {
      // console.log(number, number.length);
      const rightPart = '771 000 00 00'.slice(-(13 - number.length));
      // console.log(number, rightPart);
      return (number + rightPart).substring(0, 13);
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setTimeout(() => {
          setIsOpen(false);
        }, 200);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [setIsOpen]);

  return (
    <div
      className={`relative box-border w-full rounded-xl border-[1px] border-[var(--theme-color-blue2)] bg-[#F5F6F6] px-4 py-2 ${
        isValid || !value
          ? isFocused
            ? '!border-[#4DBAC0]'
            : '!border-[var(--theme-color-blue2)]'
          : '!border-[#FF3141]'
      }`}
    >
      <label className="mb-1 block text-sm font-medium text-black">{label}</label>

      <div className="relative flex h-10 w-full items-center text-[16px]" ref={dropdownRef}>
        <div
          onClick={toggleDropdown}
          className="relative flex w-fit cursor-pointer items-center justify-between rounded-none border-0 bg-transparent py-1 text-left focus:border-[#4DBAC0] focus:outline-none focus:ring-1 focus:ring-[#4DBAC0]"
        >
          <span className="flex w-fit items-center">
            <div className="flex h-8 w-8 items-center justify-center">
              <Flag country={selectedCountry.flag as any} />
            </div>
            <span className="mx-2 text-black">{selectedCountry.code}</span>
            <span
              className={`h-2 w-2 transform border-r-2 border-t-2 border-black ${
                isOpen ? '-rotate-45' : 'rotate-135'
              } inline-block transition-transform duration-200`}
            />
          </span>
        </div>

        <MaskInput
          value={value}
          onChange={handlePhoneChange}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          placeholder={placeholder}
        />
      </div>

      {isOpen && (
        <div className="diy-scrollbar absolute left-0 top-20 z-10 mt-1 max-h-52 w-full scale-100 overflow-auto rounded-xl border border-[#4DBAC0] bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <div className="bg-transparent px-3 py-2 text-lg text-black">Country Code</div>
          {CountryCodes.map((country: CountryCodeType, index: number) => (
            <div
              key={index}
              className="relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-teal-50"
              onClick={() => {
                onCountryChange(country);
                setIsOpen(false);
              }}
            >
              <span className="flex items-center justify-between">
                <div className="flex items-center justify-start">
                  <div className="flex h-5 w-8 items-center justify-center">
                    <Flag country={country.flag as any} />
                  </div>
                  {country.country}
                  {country.code}
                </div>
                {selectedCountry.flag === country.flag && <SelectIcon />}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PhoneInputField;
