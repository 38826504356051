import AlertIcon from '@/components/cart/AlertIcon';
import { NewLoading } from '@/components/commons';
// import message from '@/components/commons/Message';
import {
  postUserPublicEmailCaptcha, // 发邮件无需登录
  postUserPublicSmsCaptchaSend,
  postUserWebEmailCaptcha, // 发邮件需要登录
} from '@/lib/services/gonggongjiekou';
import { useRequest } from 'ahooks';
import { useTranslations } from 'next-intl';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
const COUNT_DOWN_LIMIT = 30;

const VerifyCodeWrapH5 = (props: {
  phoneNumber: string; //发信息的渠道，手机号或者邮箱
  sendType?: 'invalidInput' | 'mobile' | 'email';
  handleSubmit: () => void;
  goBack: () => void;
  setResult: (code: string[]) => void;
  butnText1?: string;
  butnText2?: string;
  loading?: boolean;
  noNeedLogin?: boolean;
  submitError?: boolean;
  setSubmitError?: React.Dispatch<React.SetStateAction<boolean>>;
  repData?: any;
  token?: any;
}) => {
  const {
    phoneNumber,
    repData,
    handleSubmit,
    goBack,
    setResult,
    butnText1,
    butnText2,
    loading,
    sendType = 'mobile',
    noNeedLogin, // 无需登录发邮件
    submitError = false,
    setSubmitError,
  } = props;
  console.log({ sendType });

  const t = useTranslations('LoregCommon');

  const [countdown, setCountdown] = useState(COUNT_DOWN_LIMIT);
  const [isCountdownActive, setIsCountdownActive] = useState(true); //开始计数

  const sendMsgRef = useRef(false); // 避免重复发短信

  const { run: sendSms } = useRequest(
    () =>
      postUserPublicSmsCaptchaSend({
        mobile: phoneNumber.replace(/(?!^\+)\D/g, ''),
        // captchaVerification: token, // captchaVerification必传,凡是短信验证之前，都需滑块验证
        ticket: repData?.ticket,
        clientUid: repData?.clientUid,
      }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.code === 0) {
          setIsCountdownActive(true);
          setCountdown(COUNT_DOWN_LIMIT);
        } else {
          // message.error(res.msg || t('failed'));
        }
      },
    },
  );
  const { run: sendSmsByEmail } = useRequest(
    () =>
      postUserWebEmailCaptcha({
        email: phoneNumber,
      }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.code === 0) {
          setIsCountdownActive(true);
          setCountdown(COUNT_DOWN_LIMIT);
        } else {
          // message.error(res.msg || t('failed'));
        }
      },
    },
  );
  const { run: sendSmsByEmailNoLogin } = useRequest(
    () =>
      postUserPublicEmailCaptcha({
        email: phoneNumber,
      }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.code === 0) {
          setIsCountdownActive(true);
          setCountdown(COUNT_DOWN_LIMIT);
        } else {
          // message.error(res.msg || t('failed'));
        }
      },
    },
  );

  useEffect(() => {
    console.log(sendType, phoneNumber, sendMsgRef.current);

    if (phoneNumber && !sendMsgRef.current) {
      // 初始化，短信只能发一次
      if (sendType === 'mobile') {
        sendSms();
        sendMsgRef.current = true;
      } else if (sendType === 'email') {
        if (noNeedLogin) {
          // 用于忘记密码的获取短信验证码，无需登录
          sendSmsByEmailNoLogin();
          sendMsgRef.current = true;
        } else {
          sendSmsByEmail();
          sendMsgRef.current = true;
        }
      }
    }
  }, [noNeedLogin, phoneNumber, sendSms, sendSmsByEmail, sendSmsByEmailNoLogin, sendType]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isCountdownActive && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      setIsCountdownActive(false);
    }
    return () => clearTimeout(timer);
  }, [isCountdownActive, countdown]);

  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  useEffect(() => {
    if (verificationCode) {
      setResult(verificationCode);
    }
  }, [verificationCode, setResult]);

  const handleCodeChange = (index: number, value: string) => {
    if (value.length <= 1) {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);
      setSubmitError?.(false);

      // Move to next input if value is entered and not the last input
      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (index: number, e: any) => {
    // Move to previous input on backspace if current input is empty
    if (e.key === 'Backspace' && !verificationCode[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleSendAgain = useCallback(() => {
    setCountdown(COUNT_DOWN_LIMIT);
    setIsCountdownActive(true);
    setVerificationCode(['', '', '', '', '', '']);
    setSubmitError?.(false);
    if (phoneNumber) {
      if (sendType === 'mobile') {
        sendSms();
      } else if (sendType === 'email') {
        sendSmsByEmail();
      }
    }
  }, [phoneNumber, sendSms, sendSmsByEmail, sendType, setSubmitError]);

  const disabled = useMemo(() => {
    return loading || verificationCode.filter((ele) => ele).length !== 6;
  }, [loading, verificationCode]);

  return (
    <div className="w-full px-5 pb-5">
      <div className="mb-4 flex w-full justify-between">
        {verificationCode.map((digit, index) => (
          <input
            key={index}
            autoFocus={index === 0}
            ref={(el) => (inputRefs.current[index] = el) as any}
            type="tel"
            maxLength={1}
            value={digit}
            onChange={(e) => handleCodeChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            className={`h-[56px] w-[56px] rounded-xl border border-[#f5f6f6] bg-[#F5F6F6] max-md:h-[50px] max-md:w-[50px] ${submitError ? 'border-[#ff3141]' : ''} text-center text-2xl focus:border-0 focus:outline-none`}
          />
        ))}
      </div>
      {submitError && (
        <div className="flex items-center px-0 py-0" role="alert">
          <AlertIcon />
          <div className="ml-1 text-[12px] text-[#ff3141]"> {t('invalidCode')}</div>
        </div>
      )}

      <div className="my-6 flex justify-center">
        <button
          className={`text-center text-sm text-[#1890FF] ${isCountdownActive ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          onClick={handleSendAgain}
          disabled={isCountdownActive}
        >
          {isCountdownActive ? `${t('sendagain')} (${countdown}s)` : `${t('sendagain')}`}
        </button>
      </div>

      <button
        className={`mb-4 w-full rounded-lg bg-[#4DBAC0] py-3 text-black hover:opacity-90 ${disabled ? '!bg-[var(--primary-clor-gray3)] !text-[var(--theme-gray-1)]' : ''} `}
        onClick={handleSubmit}
        disabled={disabled}
      >
        {loading ? <NewLoading type="special" /> : <div>{butnText1 || t('confirm')}</div>}
      </button>

      <button onClick={goBack} className="hidden w-full rounded-lg bg-[#cce8e9] py-3 text-[#4Dbac0] hover:opacity-90">
        {butnText2 || t('back')}
      </button>
    </div>
  );
};

export default VerifyCodeWrapH5;
