import { ANONYMOUS_USER_ID } from '@/shared/constants';
import storage from '@/shared/utils/storage';

export const generateUniqueSessionId = () => {
  let d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); // Use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const getAnonymousUser = () => {
  if (typeof window === 'undefined') return '';
  const id = storage.get(ANONYMOUS_USER_ID);
  if (id) return id;

  storage.set(ANONYMOUS_USER_ID, generateUniqueSessionId());
  return storage.get(ANONYMOUS_USER_ID) ?? '';
};

/**
 * 数字展示成千分位
 * @param count
 */
export const countAddSeparator = (count: number, delimiter: string = ' '): string => {
  if (count === undefined || count === null) {
    return '0';
  }

  try {
    // 四舍五入并保留两位小数
    const roundedCount = count.toFixed(2);

    // 将数字转为字符串并分离整数和小数部分
    const [integerPart, decimalPart] = roundedCount.split('.');

    // 格式化整数部分，添加千分位分隔符
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);

    // 如果小数部分为 '00'，则只返回整数部分
    if (decimalPart === '00') {
      return formattedIntegerPart;
    }

    // 否则，返回格式化后的整数部分和小数部分
    return `${formattedIntegerPart}.${decimalPart}`;
  } catch (error) {
    console.log(error);
    return '0';
  }
};

/**
 * 判断链接是图片还是视频
 * * @param url //链接地址
 */
export const checkLinkType = (url: string) => {
  const extension = url.split('.').pop()?.toLowerCase();

  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
  const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'webm'];

  if (imageExtensions.includes(extension || '')) {
    return 'Image';
  } else if (videoExtensions.includes(extension || '')) {
    return 'Video';
  } else {
    return 'Unknown';
  }
};

/**
 * 获取URL中指定参数的值
 * @param {string} paramName - 要获取的参数名
 * @returns {string|null} 参数值，如果参数不存在则返回null
 */
export const getUrlParameter = (paramName: string): string | null => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  return params.get(paramName);
};

/**
 * 生成一个用不重复的ID
 * @param randomLength id  长度
 */
export const getUuid = (randomLength = 32): string => {
  return 'key:' + Number(Math.random().toString().substring(2, randomLength) + Date.now()).toString(36);
};

/**
 * 订单状态重新分组
 */
// 这是后端代码逻辑
// <if test="status == 'UNPAID'">
// HAVING status = 'UNPAID'
// </if>
// <if test="status == 'SENT'">
// HAVING status in ('SHIPPED','ARRIVED')
// </if>
// <if test="status == 'COMPLETED'">
// HAVING status in ('COMPLETED','REFUND')
// </if>
// <if test="status == 'CANCELLED'">
// HAVING status = 'CANCELLED'
// </if>
// <if test="status == 'IN_PROGRESS' ">
// HAVING status in ('SPUPPLIER_SHIPPED','INSTOCK','WAITING_DELIVERY','PREPARE_SHIPPING','FINAL_DELIVERING','CLEARRANCE','PAID')
// </if>

/**
 * 重新分组订单状态
 * @param status - 订单状态
 * @param refundStatus - 退款状态
 * @param afterSaleStatus - 售后状态
 * @returns 重新分组后的订单状态
 */
export const orderGetNewStatus = (status: string, refundStatus?: string, afterSaleStatus?: string) => {
  // 售后状态 PENDING 待审核  RETURNING 退款中   APPROVED 已通过   REFUNDED_EXCEPTION, //退款异常  DENIED 已拒绝   REFUNDED 退款完成
  if (afterSaleStatus) {
    return afterSaleStatus === 'APPROVED' || afterSaleStatus === 'REFUNDED_EXCEPTION'
      ? 'AFTERSALEAPPROVE'
      : afterSaleStatus === 'REFUNDED'
        ? 'COMPLETED'
        : afterSaleStatus;
  } else if (refundStatus && refundStatus !== 'NONE') {
    return refundStatus;
  } else {
    if (status === 'UNPAID') {
      return 'UNPAID';
    } else if (status === 'SHIPPED' || status === 'ARRIVED') {
      return 'SENT';
    } else if (status === 'CANCELLED') {
      return 'CANCELLED';
    } else if (status === 'COMPLETED' || status === 'REFUND') {
      return 'COMPLETED';
    } else {
      return 'IN_PROGRESS';
    }
  }
};

export const wait = function (t = 2000) {
  return new Promise((res) => {
    setTimeout(() => {
      res(true);
    }, t);
  });
};

/**
 * 格式化邮箱地址，保留邮箱名称的首尾字符和@后的所有字符
 * @param {string} email - 需要格式化的邮箱地址
 * @returns {string} - 格式化后的邮箱地址
 */
export const formatEmail = function (email: string | undefined) {
  // 参数验证
  if (!email || typeof email !== 'string') {
    // throw new Error('Email must be a non-empty string');
    return '';
  }

  // 将邮箱转为小写
  email = email.toLowerCase();

  // 分割邮箱地址为用户名和域名部分
  const [username, domain] = email.split('@');

  // 如果不是有效的邮箱格式，抛出错误
  if (!username || !domain) {
    throw new Error('Invalid email format');
  }

  // 如果用户名长度小于等于2，直接返回原始邮箱
  if (username.length <= 2) {
    return `${username}@${domain}`;
  }

  // 获取用户名的第一个和最后一个字符
  const firstChar = username.charAt(0);
  const lastChar = username.charAt(username.length - 1);

  // 生成中间的星号
  const stars = '*'.repeat(username.length - 2);

  // 拼接结果
  return `${firstChar}${stars}${lastChar}@${domain}`;
};

/**
 * 在子订单里找到收货时间最晚的子订单的收货时间
 */
export const getLatestReceivingTime = (orders: any[]) => {
  return orders.reduce((latest, current) => {
    return new Date(current.receivingTime) > new Date(latest) ? current.receivingTime : latest;
  }, orders[0].receivingTime);
};

/**
 * 删掉对象中不存在的字段
 * @param {object} obj - 需要处理的对象
 * @returns {object} - 处理后的对象
 */
export const removeEmptyProperties = (obj: any) => {
  // 使用 Object.entries 和 filter 来移除属性值为 undefined 或 空字符串的项
  Object.entries(obj).forEach(([key, value]) => {
    if (value === undefined || value === '') {
      delete obj[key];
    }
  });
  return obj;
};

// 测试用例
// console.log(formatEmail('b*************V@gmail.COm')); // 'b************v@gmail.com'
// console.log(formatEmail('test@example.com')); // 't**t@example.com'
// console.log(formatEmail('ab@test.com')); // 'ab@test.com'
// console.log(formatEmail('john.doe@company.com')); // 'j*******e@company.com'
