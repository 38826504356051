// FacebookLogin.tsx
import React, { useEffect, useState, useCallback } from 'react';
import type { FacebookLoginProps, FacebookLoginResponse } from './facebook-login-types';
import { useTranslations } from 'next-intl';
import CustomButton from '@/components/logreg/commons/CustomButton';
import { FacebookIcon } from '@/components/logreg/commons/FacebookIcon';

const FacebookLogin: React.FC<FacebookLoginProps> = ({
  appId,
  onLoginSuccess,
  // onLoginFailure,
  // buttonText = '使用Facebook继续',
  // className = '',
  scope = 'public_profile,email',
  loading,
}) => {
  const t = useTranslations('Login');
  const [isSDKLoaded, setSDKLoaded] = useState<boolean>(false); // isSDKLoaded,
  // const [userStatus, setUserStatus] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initializeFacebookSDK = useCallback((): void => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId,
        cookie: true,
        xfbml: true,
        version: 'v19.0',
      });

      setSDKLoaded(true);
      // checkLoginStatus(); // 取消自动登录
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId]);

  // 手动登录
  const handleLogin = useCallback((): void => {
    if (!window.FB) return;

    setIsLoading(true);
    window.FB.login(
      (response: FacebookLoginResponse) => {
        // handleStatusChange(response);
        onLoginSuccess?.(response); // 获取 accessToken，传给后端做登录
        setIsLoading(false);
      },
      {
        scope,
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope]); // handleStatusChange,

  const loadFacebookSDK = useCallback((): void => {
    if (document.getElementById('facebook-jssdk')) return;

    const script = document.createElement('script');
    script.id = 'facebook-jssdk';
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = initializeFacebookSDK;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadFacebookSDK();

    return () => {
      const script = document.getElementById('facebook-jssdk');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [loadFacebookSDK]);

  // const baseButtonClass = 'flex items-center gap-2 px-4 py-2 rounded-md transition-colors duration-200';
  // const defaultButtonClass = `${baseButtonClass} bg-blue-600 text-white hover:bg-blue-700`;
  // const buttonClasses = className || defaultButtonClass;

  console.log(isSDKLoaded);

  // if (!isSDKLoaded) {
  //   return <div className="text-gray-500">加载中...</div>;
  // }

  return (
    <div className="flex flex-col items-center gap-4">
      {/* <button
        onClick={handleLogin}
        disabled={isLoading}
        className={`${buttonClasses} ${isLoading ? 'cursor-not-allowed opacity-50' : ''}`}
        type="button"
        aria-busy={isLoading}
      >
        <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M12 0C5.373 0 0 5.373 0 12c0 6.016 4.432 10.984 10.207 11.852V15.18H7.237v-3.154h2.97V9.927c0-3.475 1.693-5.047 4.784-5.047 1.442 0 2.213.107 2.574.157v2.83h-2.034c-1.285 0-1.74.86-1.74 2.012v2.148h3.72l-.49 3.154h-3.23v8.672C19.568 22.984 24 18.016 24 12c0-6.627-5.373-12-12-12z" />
        </svg>
        {isLoading ? '登录中...' : buttonText}
      </button> */}

      <CustomButton
        color="bg-[#4267B2] text-white"
        onClick={handleLogin}
        loading={isLoading || loading}
        icon={(props: any) => <FacebookIcon {...props} />}
      >
        {t('ContinuewithFacebook')}
      </CustomButton>
      {/* {userStatus && (
        <div className="text-gray-700" role="status" aria-live="polite">
          {userStatus}
        </div>
      )} */}
    </div>
  );
};

export default FacebookLogin;
