const CustomModalH5 = ({
  children: Content,
  triggerIcon: TriggerIcon,
  // closeIcon: CloseIcon = X,
  isOpen = false,
  setIsOpen,
  callback,
  width = 'w-fit',
  // classNmases = {},
}: {
  children?: any;
  triggerIcon?: any;
  closeIcon?: any;
  isOpen?: any;
  setIsOpen: any;
  width?: 'w-fit' | 'w-full';
  callback?: () => void;
  classNmases?: any;
}) => {
  // const { contentClassName } = classNmases;
  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    if (callback) {
      callback(); // 关闭弹层的回调
    }
  };

  const handleBackgroundClick = (e: any) => {
    if (e.target === e.currentTarget) {
      console.log(closeModal);

      closeModal();
    }
  };

  const handleContentClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <>
      {TriggerIcon ? (
        <div onClick={openModal} className={`${width} cursor-pointer`}>
          {TriggerIcon}
        </div>
      ) : (
        <button onClick={openModal} className="rounded bg-blue-500 px-4 py-0 text-white hover:bg-blue-600">
          Open Modal
        </button>
      )}

      {isOpen && (
        <div
          className="fixed inset-0 z-[999] flex items-start justify-center bg-black bg-opacity-30"
          onClick={handleBackgroundClick}
        >
          <div className="relative w-full border-0 bg-transparent" onClick={handleContentClick}>
            <div className="bg-transparent p-0">{Content}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomModalH5;
