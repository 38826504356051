const NotCheckedIcon = ({ width = '16', height = '16', color = '#80878E' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_12459_146719)">
        <path
          d="M10.6895 6.68688C10.8848 6.49162 10.8848 6.17504 10.6895 5.97977C10.4942 5.78451 10.1776 5.78451 9.98238 5.97977L7.0026 8.95955L6.02282 7.97977C5.82756 7.78451 5.51098 7.78451 5.31572 7.97977C5.12046 8.17504 5.12046 8.49162 5.31572 8.68688L6.64905 10.0202C6.84431 10.2155 7.1609 10.2155 7.35616 10.0202L10.6895 6.68688Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.0026 0.833328C4.04456 0.833328 0.835938 4.04195 0.835938 8C0.835938 11.958 4.04456 15.1667 8.0026 15.1667C11.9606 15.1667 15.1693 11.958 15.1693 8C15.1693 4.04195 11.9606 0.833328 8.0026 0.833328ZM1.83594 8C1.83594 4.59424 4.59685 1.83333 8.0026 1.83333C11.4084 1.83333 14.1693 4.59424 14.1693 8C14.1693 11.4058 11.4084 14.1667 8.0026 14.1667C4.59685 14.1667 1.83594 11.4058 1.83594 8Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_12459_146719">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NotCheckedIcon;
