import React from 'react';
import { Check } from 'lucide-react';
import { useLocale } from 'next-intl';
import CheckPassIcon from './icon/NotCheckedIcon';
import PassCheckedIcon from './icon/CheckedIcon';
import NotCheckedIcon from './icon/NotCheckedIcon';

interface PasswordValidationProps {
  validationState?: {
    lengthSufficient: boolean;
    withCapital: boolean;
    withNumber: boolean;
    withSpecial: boolean;
    withoutLatin: boolean;
    withoutSpace: boolean;
  };
}

const PasswordValidation: React.FC<PasswordValidationProps> = ({ validationState }) => {
  const currentLocale = useLocale();

  const requirements = [
    {
      key: 'lengthSufficient',
      label: {
        ru: 'Минимум 8 символов',
        en: 'Minimum 8 characters',
        kk: 'Кемінде 8 таңба',
      },
    },
    {
      key: 'withNumber',
      label: {
        ru: 'Цифра',
        en: 'Number',
        kk: 'Сан',
      },
    },
    {
      key: 'withCapital',
      label: {
        ru: 'Заглавная буква',
        en: 'Capital letter',
        kk: 'Бас әріп',
      },
    },
    {
      key: 'withSpecial',
      label: {
        ru: 'Символ (!@_)',
        en: 'Symbol (!@_)',
        kk: 'Таңба (!@_)',
      },
    },
  ];

  return (
    <div className="grid grid-cols-2 gap-2">
      {requirements.map(({ key, label }) => (
        <div key={key} className="flex items-center gap-2">
          <div className={`flex h-5 w-5 items-center justify-center rounded-full`}>
            {validationState?.[key as keyof typeof validationState] ? <PassCheckedIcon /> : <NotCheckedIcon />}
          </div>
          <span
            className={`text-sm ${
              validationState?.[key as keyof typeof validationState] ? 'text-[#4DBAC0]' : 'text-gray-500'
            }`}
          >
            {label[currentLocale as 'en' | 'ru' | 'kk']}
          </span>
        </div>
      ))}
    </div>
  );
};

export default PasswordValidation;
