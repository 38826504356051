import { useToast } from '@/components/ui/use-toast';
import copy from 'copy-to-clipboard';
import { useLocale, useTranslations } from 'next-intl';
import Image from 'next/image';

const ShareBox = ({
  productDetail,
}: {
  productDetail: {
    id: string;
    subject: string;
    subjectEN?: string;
    subjectKZ?: string;
    productImages: string[];
  };
}) => {
  const t = useTranslations('Product');
  const { toast } = useToast();
  const currentLocale = useLocale();

  return (
    <div className="box-border h-auto w-[416px] rounded-3xl bg-white p-6">
      <div className="mb-6 w-full text-center text-base font-semibold text-black">{t('share')}</div>
      <div className="mb-6 flex w-full flex-row items-start justify-between">
        <Image
          src={productDetail.productImages[0]}
          className="mr-2 h-[60px] w-[60px] rounded object-cover"
          width={60}
          height={60}
          alt="Image"
        />
        <div style={{ width: 'calc(100% - 60px - 8px' }} className="flex-1">
          <div className="mb-1 truncate text-base font-medium text-black">
            {currentLocale === 'en'
              ? productDetail?.subjectEN
              : currentLocale === 'ru'
                ? productDetail?.subject
                : productDetail?.subjectKZ}
          </div>
          <div className="flex w-full flex-row items-center justify-start">
            <div className="mr-1 text-base font-medium text-[#80878E]">{t('itemNumber')}:</div>
            <div className="text-base font-medium text-black"> {productDetail?.id}</div>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-row items-start justify-between">
        <div className="flex w-[86px] flex-col items-center justify-center">
          <Image
            src={'/images/product/whatsApp.png'}
            className="mb-2 h-12 w-12 cursor-pointer"
            width={48}
            height={48}
            alt="whatsAppImage"
            onClick={() => {
              const shareUrl = `https://web.whatsapp.com/send?text=${encodeURIComponent(
                currentLocale === 'en'
                  ? productDetail?.subjectEN + ''
                  : currentLocale === 'ru'
                    ? productDetail?.subject + ''
                    : productDetail?.subjectKZ + '',
              )}&url=${encodeURIComponent(window.location.href)}`;
              console.log(shareUrl);
              window.open(shareUrl);
            }}
          />
          <div className="w-full text-center text-xs text-black">{t('whatsApp')}</div>
        </div>
        <div className="flex w-[86px] flex-col items-center justify-center">
          <Image
            src={'/images/product/telegram.png'}
            className="mb-2 h-12 w-12 cursor-pointer"
            width={48}
            height={48}
            alt="messageImage"
            onClick={() => {
              const shareUrl = `https://t.me/share?url=${encodeURIComponent(
                window.location.href,
              )}&text=${encodeURIComponent(productDetail?.subject)}`;
              console.log(shareUrl);
              window.open(shareUrl);
            }}
          />
          <div className="w-full text-center text-xs text-black">{t('telegram')}</div>
        </div>
        <div className="flex w-[86px] flex-col items-center justify-center">
          <Image
            src={'/images/product/facebook.png'}
            className="mb-2 h-12 w-12 cursor-pointer"
            width={48}
            height={48}
            alt="facebookImage"
            onClick={() => {
              const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                window.location.href,
              )}`;
              console.log(shareUrl);
              window.open(shareUrl);
            }}
          />
          <div className="w-full text-center text-xs text-black">{t('facebook')}</div>
        </div>
        <div className="flex w-[86px] flex-col items-center justify-center">
          <Image
            src={'/images/product/link.svg'}
            className="mb-2 h-12 w-12 cursor-pointer"
            width={48}
            height={48}
            alt="facebookImage"
            onClick={() => {
              try {
                copy(location.href);
                toast({
                  description: (
                    <div className="flex flex-row items-center justify-start font-semibold">
                      <Image
                        src={'/images/product/messages.png'}
                        width={24}
                        height={24}
                        className="mr-2 size-6"
                        alt="productImages"
                      />{' '}
                      {t('copyduccessfulUrl')}
                    </div>
                  ),
                });
              } catch (err) {
                console.error('Failed to copy text: ', err);
              }
            }}
          />
          <div className="w-full text-center text-xs text-black">{t('copyTheLink')}</div>
        </div>
      </div>
    </div>
  );
};

export default ShareBox;
