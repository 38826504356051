// @ts-nocheck
function blobToDataURL(blob) {
  const a = new FileReader();
  a.readAsDataURL(blob);
  return new Promise<string>(function (resolve) {
    a.onload = function (e) {
      resolve(e.target?.result as string);
    };
  });
}

export async function loadImage(url: string | Blob): Promise<HTMLImageElement> {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  if (typeof url === 'string') {
    img.src = url;
  } else {
    img.src = await blobToDataURL(url);
  }

  return new Promise<HTMLImageElement>(function (resolve) {
    img.onload = () => resolve(img);
  });
}

export async function imageToArrayBuffer(img: HTMLImageElement): Promise<ArrayBuffer> {
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d');
  c.width = img.width;
  c.height = img.height;
  ctx?.drawImage(img, 0, 0);

  return new Promise<ArrayBuffer>(function (resolve) {
    c.toBlob((v) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      v && resolve(v.arrayBuffer?.());
    });
  });
}

export async function resizeImage(
  img: HTMLImageElement,
  width: number,
  height: number,
): Promise<{
  blob: Blob;
  url: string;
}> {
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d');
  c.width = width;
  c.height = height;
  ctx?.drawImage(img, 0, 0, width, height);
  const dataURL = c.toDataURL('image/png', 1); //base64组成的图片的源数据
  return new Promise<{
    blob: Blob;
    url: string;
  }>(function (resolve) {
    c.toBlob((v: Blob) => {
      resolve({
        blob: v,
        url: dataURL,
      });
    });
  });
}
