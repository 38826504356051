// 封装
import { Input } from '@/components/ui/input';
import { X } from 'lucide-react';
import { ForwardedRef, useState } from 'react';
import React from 'react';

const CustomInput = React.forwardRef(({ onClear, ...props }: any, ref: ForwardedRef<HTMLInputElement>) => {
  // console.log(props);

  const [showClear, setShowClear] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e);
    setShowClear(e.target.value !== '');
  };

  const handleClear = () => {
    onClear();
    setShowClear(false);
  };
  return (
    <div className="relative">
      <Input
        {...props}
        ref={ref}
        // clearable
        onChange={handleChange}
        className="my-0 border-none bg-transparent p-0 shadow-none placeholder:text-slate-300 focus-visible:ring-0 focus-visible:ring-offset-0"
      />
      {showClear && (
        <button
          type="button"
          onClick={handleClear}
          className="absolute right-2 top-1/2 hidden -translate-y-1/2 transform"
        >
          <X size={1 / 6} className="text-[#9ba1a8]" />
        </button>
      )}
    </div>
  );
});
CustomInput.displayName = 'CustomInput';

export default CustomInput;
