const Icon5 = ({ width = '27', height = '30' }) => (
  <svg width={width} height={height} viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.0843 2.70457C13.8909 2.64244 13.6794 2.6575 13.4997 2.74407C13.303 2.83886 13.173 3.00429 13.1263 3.18429L12.492 5.62944C12.2702 6.48454 11.9473 7.30932 11.5313 8.088C10.8855 9.29692 9.90843 10.2267 9.00196 11.0078L7.08364 12.6609C6.78633 12.9171 6.62987 13.3011 6.66376 13.6931L7.74664 26.2166C7.80135 26.8493 8.32992 27.3334 8.9618 27.3334H15.16C19.3417 27.3334 22.865 24.4237 23.5408 20.5162L24.4815 15.0765C24.611 14.3279 24.0352 13.6456 23.2797 13.6456H16.3716C15.0688 13.6456 14.0792 12.4768 14.2895 11.193L15.1731 5.80023C15.2941 5.06153 15.2596 4.30566 15.0716 3.58114C14.9762 3.21347 14.6861 2.89791 14.2776 2.76666L14.0843 2.70457L14.3901 1.75249L14.0843 2.70457ZM12.6315 0.942346C13.275 0.632282 14.0163 0.582079 14.696 0.800412L14.8893 0.862506L14.5834 1.81459L14.8893 0.862507C15.9253 1.19532 16.7336 2.02294 17.0075 3.07896C17.2654 4.07304 17.3128 5.1101 17.1468 6.1236L16.2632 11.5163C16.2519 11.5854 16.3051 11.6456 16.3716 11.6456H23.2797C25.2788 11.6456 26.7927 13.4487 26.4523 15.4173L25.5115 20.857C24.6611 25.7747 20.2617 29.3334 15.16 29.3334H8.9618C7.29026 29.3334 5.89803 28.0537 5.75408 26.3889L4.67119 13.8654C4.58199 12.8337 4.99363 11.8218 5.77807 11.1458L7.69638 9.49274C8.57 8.73992 9.30539 8.01025 9.76722 7.14567C10.1093 6.50525 10.3743 5.82811 10.5561 5.12725L11.1904 2.68211C11.3894 1.91504 11.9222 1.28414 12.6315 0.942346ZM1.45689 11.6465C1.99191 11.6235 2.45015 12.0259 2.49628 12.5595L3.79175 27.5415C3.87496 28.5039 3.11691 29.3334 2.14894 29.3334C1.23719 29.3334 0.5 28.5937 0.5 27.6837V12.6456C0.5 12.1101 0.921868 11.6696 1.45689 11.6465Z"
      fill="#4DBAC0"
    />
  </svg>
);

export default Icon5;
