import { useTranslations } from 'next-intl';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import AutoHeightIframe from './AutoHeightIframe';
import { messageUtil } from '@/shared/utils/messageUtil';
// import { messageUtil } from '@/shared/utils/CrossWindowEventEmitter';
// import EM from '@/shared/utils/EM';

// 添加自定义滚动条样式
const customScrollbarStyles = `
  .custom-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  .custom-scrollbar-container {
    position: relative;
  }

  .custom-scrollbar-container:hover .custom-scrollbar-track {
    opacity: 1;
  }

  .custom-scrollbar-track {
    position: absolute;
    top: 120px;
    right: 6px;
    bottom: 120px;
    width: 6px;
    background-color: transparent;
    border-radius: 3px;
    opacity: 0;
    transition: opacity 0.2s;
    overflow: hidden; /* 防止滑块溢出 */
  }

  .custom-scrollbar-thumb {
    position: absolute;
    width: 100%;
    min-height: 30px; /* 设置最小高度 */
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 3px;
    cursor: pointer;
    transform: translateY(0);
    transition: transform 0.1s;
  }
`;

const PolicyTermsCommonComp = (props: any) => {
  const t = useTranslations('Orderprocess');
  const { termsChecked, setTermsChecked, close, articleContent, url } = props;
  const [isBottomReached, setIsBottomReached] = useState(termsChecked || false);
  const contentRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);
  const [thumbHeight, setThumbHeight] = useState(0);
  const [thumbTop, setThumbTop] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [startScrollTop, setStartScrollTop] = useState(0);

  const updateThumbPosition = useCallback(() => {
    if (contentRef.current && trackRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      const trackHeight = trackRef.current.clientHeight;

      // 计算滚动条高度，确保至少 30px
      const scrollableHeight = scrollHeight - clientHeight;
      const ratio = clientHeight / scrollHeight;
      const thumbHeightValue = Math.max(30, trackHeight * ratio);
      setThumbHeight(thumbHeightValue);

      // 计算可滚动的轨道高度（轨道总高度减去滑块高度）
      const availableTrackHeight = trackHeight - thumbHeightValue;

      // 计算滑块位置，确保不会超出轨道
      const scrollPercent = scrollTop / scrollableHeight;
      const thumbTopValue = Math.min(availableTrackHeight, Math.max(0, scrollPercent * availableTrackHeight));
      setThumbTop(thumbTopValue);

      // 检查是否到达底部
      if (scrollHeight - scrollTop - clientHeight < 50) {
        setIsBottomReached(true);
        setTermsChecked?.(true);
      }
    }
  }, [setTermsChecked]);

  useEffect(() => {
    const fn = function (v: boolean) {
      console.log(v);

      if (v === true) {
        //
      }
      if (v === false) {
        // alert(1);
        setTimeout(updateThumbPosition, 800); // 获取到文章接口数据后，延迟0.5秒 更新可滚动的轨道高度
      }
    };
    messageUtil.on('article_loading', fn);

    return () => {
      messageUtil.off('article_loading', fn);
    };
  }, [updateThumbPosition]);

  useEffect(() => {
    const element = contentRef.current;
    if (element) {
      element.addEventListener('scroll', updateThumbPosition);
      // 初始化滚动条
      if (articleContent || url) {
        // setTimeout(updateThumbPosition, 2000);
      }

      return () => element.removeEventListener('scroll', updateThumbPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleContent]);

  // 处理鼠标拖动
  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(true);
    setStartY(e.clientY);
    if (contentRef.current) {
      setStartScrollTop(contentRef.current.scrollTop);
    }
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging && contentRef.current && trackRef.current) {
        e.preventDefault();
        const deltaY = e.clientY - startY;
        const trackHeight = trackRef.current.clientHeight - thumbHeight;
        const scrollableHeight = contentRef.current.scrollHeight - contentRef.current.clientHeight;

        // 计算滚动比例
        const scrollRatio = scrollableHeight / trackHeight;
        const newScrollTop = Math.min(scrollableHeight, Math.max(0, startScrollTop + deltaY * scrollRatio));

        contentRef.current.scrollTop = newScrollTop;
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, startY, startScrollTop, thumbHeight]);

  // .replace(/www\./i, '')
  return (
    <>
      <style>{customScrollbarStyles}</style>
      <div className="relative h-[8/10] flex-col overflow-hidden p-4 max-md:absolute max-md:bottom-0 max-md:z-50 max-md:h-[90vh] max-md:w-full max-md:p-0">
        <div className="custom-scrollbar-container h-[80vh] max-h-[900px] w-[80vw] max-w-[1000px] rounded-lg bg-white px-8 py-10 pb-20 shadow-lg max-md:h-[97vh] max-md:w-full max-md:px-0 max-md:pb-40">
          <div ref={contentRef} className="custom-scrollbar h-full w-full overflow-y-auto pr-4">
            {articleContent && (
              <div className="prose prose-sm max-w-none" dangerouslySetInnerHTML={{ __html: articleContent }} />
            )}
            {url && <AutoHeightIframe src={url} className="w-full" />}
          </div>
          <div ref={trackRef} className="custom-scrollbar-track">
            <div
              ref={thumbRef}
              className="custom-scrollbar-thumb"
              style={{
                height: `${thumbHeight}px`,
                transform: `translateY(${thumbTop}px)`,
              }}
              onMouseDown={handleMouseDown}
            />
          </div>
        </div>
        {close && (
          <div className="absolute bottom-5 left-0 box-border flex w-full justify-center rounded-b-lg p-0">
            <button
              onClick={close}
              className={`w-48 rounded-md py-2 font-semibold text-black max-md:w-[90%] max-md:rounded-xl ${
                isBottomReached ? 'bg-[#4DBAC0] hover:opacity-80' : 'cursor-not-allowed bg-gray-400 text-white'
              }`}
              disabled={!isBottomReached}
            >
              {t('agree')}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default PolicyTermsCommonComp;
