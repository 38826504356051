import { useCallback } from 'react';

interface UseFormattedInputOptions {
  formatFn: (value: string, ...args: any[]) => string;
  formatArgs: any[];
  shouldRemoveNonDigits?: boolean;
}

// {
//   formatFn,
//   formatArgs = [],
//   shouldRemoveNonDigits = true,
// }: UseFormattedInputOptions
export const useFormattedInput = () => {
  const handleFormattedChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
      optipns: UseFormattedInputOptions,
    ) => {
      const { shouldRemoveNonDigits, formatFn, formatArgs } = optipns;
      const input = e.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;

      // 记录原始值中光标位置之前的空格数量
      const spacesBeforeCursor = input.value.slice(0, start || 0).split(' ').length - 1;

      // 处理输入值
      let value = input.value;
      if (shouldRemoveNonDigits) {
        value = value.replaceAll(/\D/g, '');
      }

      // 格式化
      const formattedValue = value ? formatFn(value, ...formatArgs) : '';

      // 设置新值
      input.value = formattedValue;

      // 调用原始的 onChange
      if (onChange) {
        onChange(e);
      }

      // 计算并设置新的光标位置
      if (start !== null && end !== null) {
        const newSpacesBeforeCursor = formattedValue.slice(0, start).split(' ').length - 1;
        const spaceDiff = newSpacesBeforeCursor - spacesBeforeCursor;
        const newPosition = start + spaceDiff;

        setTimeout(() => {
          input.setSelectionRange(newPosition, newPosition);
        }, 0);
      }
    },
    [],
  );

  return handleFormattedChange;
};
